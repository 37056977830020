export enum SessionKey {
  USER_INFO = 'HCM_USER_INFO',
  TOKEN = 'HCM_TOKEN',
  CURRENT_USERNAME = 'HCM_CURRENT_USERNAME',
  ROUTER_PENDING = 'HCM_ROUTER_PENDING',
  CURRENT_FUNCTION = 'HCM_CURRENT_FUNCTION',
  REFRESH_TOKEN = 'HCM_REFRESH_TOKEN',
  EXP_TOKEN = 'HCM_EXP_TOKEN',
  EXP_REFRESH_TOKEN = 'HCM_EXP_REFRESH_TOKEN',
  CONTRACT_NUMBER = 'CONTRACT_NUMBER',
  PREVIOUS_URL_BEFORE_LOGIN = 'PREVIOUS_URL_BEFORE_LOGIN',
  CONFIRMED_DECREE = 'CONFIRMED_DECREE',
  VIEW_ASSESSMENT = 'VIEW_ASSESSMENT',
}

export enum ScanKey {
  scan_1 = 'scan_1',
  scan_2 = 'scan_2',
  scan_DGC = 'Quét đã gồm công',
  scan_CGC = 'Quét chưa gồm công',
}

export enum ScreenType {
  Create,
  Update,
  Detail,
}

export enum EmployeeInfoTab {
  personalInfo = 'personalInfo', // thông tin cá nhân
  workingProcessAtMc = 'workingProcessAtMc', // quá trình công tác tại mc
  contractProcess = 'contractProcess', // quá trình hợp đồng
  resultOfReview = 'resultOfReview', // kết quả đánh giá
  certificate = 'certificate', // bằng cấp chứng chỉ
  profileInfo = 'profileInfo', // thông tin hồ sơ
  relative = 'relative', // thân nhân
  reward = 'reward', // khen thưởng
  penalize = 'penalize', // kỉ luật
  workPlace = 'workPlace', // nơi làm việc
  workDescribe = 'workDescribe',
  directManagement = 'directManagement', // cán bộ quả lý trực tiếp
  bankInsurrance = 'bankInsurrance', // ngân hàng thuế bảo hiểm
  workingProcessBeforeMc = 'workingProcessBeforeMc', // quá trình làm việc trước MC
  otherInfo = 'otherInfo', // thông tin khác
}

export enum FunctionCode {
  ORG = 'ORG',
  WORK_LOCATION = 'WORK_LOCATION',
  CONTRACT_AUTHORIZATION = 'CONTRACT_AUTHORIZATION',
  BUSINESS_INFO = 'BUSSINESS_INFO',
  ORG_MODEL = 'ORG_MODEL',
  JOB = 'JOB',
  JOB_DESCRIPTION = 'JOB_DESCRIPTION',
  CONTRACT_EXPIRATION = 'CONTRACT_EXPIRATION',
  CONTRACT_FORM = 'CONTRACT_FORM',
  CONTRACT_CHANGE_ASSESSMENT = 'CONTRACT_CHANGE_ASSESSMENT',
  ORG_CHART = 'ORG_CHART',
  TRANSFER_ORG_MODEL = 'TRANSFER_ORG_MODEL',
  ORG_TREE = 'ORG_TREE',
  ORG_TREE_PAST = 'ORG_TREE_PAST',
  MANAGEMENT_UNIT = 'MANAGEMENT_UNIT',
  AFFILIATED_UNIT = 'AFFILIATED_UNIT',
  DRAFT_ORG_MODEL = 'DRAFT_ORG_MODEL',
  MAPPING_ORG_MODEL = 'MAPPING_ORG_MODEL',
  EMP = 'EMP',
  DRAFT_TRANSFER_OF_PERSONNEL = 'DRAFT_TRANSFER_OF_PERSONNEL',
  JOB_DRAFT = 'JOB_DRAFT',
  TRANSFER_ORG_MODEL_CHILD = 'TRANSFER_ORG_MODEL_CHILD',
  ONBOARDING = 'ONBOARDING',
  OBN_LIST = 'OBN_LIST',
  OBN_CANDIDATE_INFO_LIST = 'OBN_CANDIDATE_INFO_LIST',
  TRANS_EMP_LIST = 'TRANS_EMP_LIST',
  CREATE_ACC_LIST = 'CREATE_ACC_LIST',
  PREPARE_OBN_LIST = 'PREPARE_OBN_LIST',
  REPORT_LIST = 'REPORT_LIST',
  CATEGORY = 'CATEGORY',
  STAFF_PLAN = 'STAFF_PLAN',
  CABINET_ONL = 'CABINET_ONL',
  RESIGN = 'RESIGN',
  CONTRACT_MANAGE = 'CONTRACT_MANAGE',
  REPORT = 'REPORT',
  EXTENSION = 'EXTENSION',
  EMP_INFO = 'EMP_INFO',
  APPROVAL_REQUEST_LIST = 'APPROVAL_REQUEST_LIST',
  CONFIG = 'CONFIG',
  EMP_LIST = 'EMP_LIST',
  CONTRACT_SELF_ASSESSMENT = 'CONTRACT_SELF_ASSESSMENT',
  CONTRACT_SELF_ASSESSMENT_ACTIVE = 'CONTRACT_SELF_ASSESSMENT_ACTIVE',
  OFFER_TYPE_TRANS = 'OFFER_TYPE_TRANS',
  CONTRACT_ASSESSMENT_BROWSER = 'CONTRACT_ASSESSMENT_BROWSER',
  CONTRACT_PRINT = 'CONTRACT_PRINT',
  RESIGN_LIST = 'RESIGN_LIST',
  FORM_QUIT_MANAGE = 'FORM_QUIT_MANAGE',
  TRANSFER_RESULT = 'TRANSFER_RESULT',
  DUTY_LIST = 'DUTY_LIST',
  HAND_OVER = 'HAND_OVER',
  DOCUMENT_BOOK = 'DOCUMENT_BOOK',
  RESIGNATION = 'RESIGNATION',
  RESIGN_REMEMBER = 'RESIGN_REMEMBER',
  OPERATE_RESIGN_LIST = 'OPERATE_RESIGN_LIST',
  LIST_FROM_INFO_MANAGE = 'LIST_FROM_INFO_MANAGE',
  REPORT_FULL_INFO = 'REPORT_FULL_INFO',
  ASSESSMENT_CRITERIA = 'ASSESSMENT_CRITERIA',
  CONTRACT_CONCLUSION_ASSESSMENT = 'CONTRACT_CONCLUSION_ASSESSMENT',
  ASSIGNMENT_OF_MANAGEMENT_LIST = 'ASSIGNMENT_OF_MANAGEMENT_LIST',
  ASSIGNMENT_OF_MANAGEMENT = 'ASSIGNMENT_OF_MANAGEMENT',
  CONFIG_CEO = 'CONFIG_CEO',
  CONFIG_HR = 'CONFIG_HR',
  CONFIG_SERVICE = 'CONFIG_SERVICE',
  CONFIG_RECRUIT = 'CONFIG_RECRUIT',
  SERVEY = 'SERVEY',
  CONFIG_ADMIN = 'CONFIG_ADMIN',
  FILE_CATEGOORY = 'FILE_CATEGOORY',
  PROFILE_INFORMATION = 'PROFILE_INFORMATION',
  FILE_ROLE = 'FILE_ROLE',
  LESS_REPORT_FILE = 'LESS_REPORT_FILE',
  LESS_REPORT_DETAIL_FILE = 'LESS_REPORT_DETAIL_FILE',
  REGIS_BORROW = 'REGIS_BORROW',
  APPROVAL_REGIS_UNIT = 'APPROVAL_REGIS_UNIT',
  APPROVAL_REGIS_HR = 'APPROVAL_REGIS_HR',
  FOLLOW_FILE = 'FOLLOW_FILE',
  ADMINISTRATION = 'ADMINISTRATION',
  CATEGORY_ADMIN = 'CATEGORY_ADMIN',
  MASTER_DATA = 'MASTER_DATA',
  ACCOUNT = 'ACCOUNT',
  USER_GROUP = 'USER_GROUP',
  ROLE = 'ROLE',
  PERMISSION_DATA = 'PERMISSION_DATA',
  PROCESS_MANAGE = 'PROCESS_MANAGE',
  TEMPLATE_MANAGE = 'TEMPLATE_MANAGE',
  SYSTEM = 'SYSTEM',
  CONFIG_DATA = 'CONFIG_DATA',
  COMMON_CATEGORY = 'COMMON_CATEGORY',
  NATIONALITY = 'NATIONALITY',
  ETHNIC = 'ETHNIC',
  RELIGION = 'RELIGION',
  BANK = 'BANK',
  SPECIALIZED = 'SPECIALIZED',
  CERTIFICATE = 'CERTIFICATE',
  CAREER = 'CAREER',
  AREA = 'AREA',
  GROUP = 'GROUP',
  CAPACITY_GROUP = 'CAPACITY_GROUP',
  ABILITY = 'ABILITY',
  CONTRACT_CATEGORY = 'CONTRACT_CATEGORY',
  RECEIVE_HAND_OVER_LIST = 'RECEIVE_HAND_OVER_LIST',
  CONFIRM_HAND_OVER = 'CONFIRM_HAND_OVER',
  DASHBOARD = 'DASHBOARD',
  WORKPLACE = 'WORKPLACE',
  DISCIPLINE_LIST = 'DISCIPLINE_LIST',
  WORKING_PROCESS_MC = 'WORKING_PROCESS_MC',
  RESULT_REVIEW = 'RESULT_REVIEW',
  DEVELOPMENT_OF_ACCESSORY = 'DEVELOPMENT_OF_ACCESSORY',
  SALARY_MCCTT = 'SALARY_MCCTT',
  SALARY_BONUS = 'SALARY_BONUS',
  SALARY_INPUT = 'SALARY_INPUT',
  TIMESHEET = 'TIMESHEET',
  TIMEKEEPING_PERIOD = 'TIMEKEEPING_PERIOD',
  ASSIGN_OFF_SCHEDULE = 'ASSIGN_OFF_SCHEDULE',
  ASSIGN_WORK_SCHEDULE = 'ASSIGN_WORK_SCHEDULE',
  HOLIDAY_CALENDER = 'HOLIDAY_CALENDER',
  INCOME_ADJUSTMENT = 'INCOME_ADJUSTMENT',
  REGISTEER_OFF = 'REGISTEER_OFF',
  REGISTEER_OFF_CREATE = 'REGISTEER_OFF',
  LIST_OFF = 'LIST_OFF',
  APPROVE_LEAVE_OF_ABSENCE = 'APPROVE_LEAVE_OF_ABSENCE',
  BONUS_BASE_LIST = 'BONUS_BASE_LIST',
  BONUS_BASE_DEVELOPMENT = 'BONUS_BASE_DEVELOPMENT',
  REMUNERATION = 'REMUNERATION',
  TIMESHEETS = 'TIMESHEETS',
  APPROVE_TIMESHEETS = 'APPROVE_TIMESHEETS',
  APPROVAL_OFF = 'APPROVAL_OFF',
  DEVELOPMENT_REMUNERATION_EMP = 'DEVELOPMENT_REMUNERATION_EMP',
  ALLOWANCE_LIST = 'ALLOWANCE_LIST',
  POLICY_IMPLEMENTATION = 'POLICY_IMPLEMENTATION',
  SALARY_OFFSET = 'SALARY_OFFSET',
  INSURANCE_INFO = 'INSURANCE_INFO',
  INSURANCE_UP = 'INSURANCE_UP',
  INSURANCE_ADJUSTMENT = 'INSURANCE_ADJUSTMENT',
  SALARY_RANGE = 'SALARY_RANGE',
  INSURANCE_DECLARATION = 'INSURANCE_DECLARATION',
  INSURANCE_DOWN = 'INSURANCE_DOWN',
  TAKE_INSURANCE = 'TAKE_INSURANCE',
  PAYROLL = 'PAYROLL',
  SALARY_PERIOD = 'SALARY_PERIOD',
  INSURANCE_DECLARE_LIST = 'INSURANCE_DECLARE_LIST',
  SEVERANCE_ALLOWANCE = 'SEVERANCE_ALLOWANCE',
  SALARY_HOLDING = 'SALARY_HOLDING',
  SALARY_EVOLUTION = 'SALARY_EVOLUTION',
  MIGRATE_SL = 'MIGRATE_SL',
  MIGRATE_INS = 'MIGRATE_INS',
  MIGRATE_EMP = 'MIGRATE_EMP',
  MIGRATE_ORG = 'MIGRATE_ORG',
  CALCULATE_MONTH_SALARY = 'CALCULATE_MONTH_SALARY',
  MONTH_SALARY = 'MONTH_SALARY',
  RENEWS_CONTRACT = 'RENEWS_CONTRACT',
  DEDUCTION = 'DEDUCTION',
  ORG_POSITION = 'ORG_POSITION',
  ORG_TITLE = 'ORG_TITLE',
  EMP_ORG = 'EMP_ORG',
  PAYSLIP = 'PAYSLIP',
  CURRENT_ORG = 'CURRENT_ORG',
  EXPECTED_ORG = 'EXPECTED_ORG',
  TRANS_ORG_MODEL = 'TRANS_ORG_MODEL',
  LIST_OFF_CREATE = 'LIST_OFF_CREATE',
  SALARY_CALCULATION = 'SALARY_CALCULATION',
  HOLDING_FINALIZATION = 'HOLDING_FINALIZATION',
  TIMESHEETS_BACKDATE = 'TIMESHEETS_BACKDATE',
  MONTHLY_PERSONAL_INCOME_TAX = 'MONTHLY_PERSONAL_INCOME_TAX',
  APPROVE_TIMESHEETS_BACKDATE = 'APPROVE_TIMESHEETS_BACKDATE',
  YOURSELF_DEVELOPMENT_REMUNERATION_EMP = 'YOURSELF_DEVELOPMENT_REMUNERATION_EMP',
  YOURSELF_DEVELOPMENT_OF_ACCESSORY = 'YOURSELF_DEVELOPMENT_OF_ACCESSORY',
  YOURSELF_BONUS_BASE_DEVELOPMENT = 'YOURSELF_BONUS_BASE_DEVELOPMENT',
  CONTRACT_OPERATION = 'CONTRACT_OPERATION',
  ORG_TREE_DRAFT = 'ORG_TREE_DRAFT',
  EMP_REPORT_FULL_DATA = 'EMP_REPORT_FULL_DATA',
  ESTABLISH_SCHEDULE_EMPLOYEE = 'ESTABLISH_SCHEDULE_EMPLOYEE',
  SALARY_CALCULATION_RECOVERY = 'SALARY_CALCULATION_RECOVERY',
  MIGRATE_TAX = 'MIGRATE_TAX',
  KPIS_COMPANY = 'KPIS_COMPANY',
  KPI_UNIT = 'KPI_UNIT',
  SALARY_PERIOD_CATEGORY = 'SALARY_PERIOD_CATEGORY',
  KPI_BOTTOM = 'KPI_BOTTOM',
  OBN_GUIDE = 'OBN_GUIDE',
  KPI_PERSONAL = 'KPI_PERSONAL',
  KPIS = 'KPIS',
  KPI_ORG = 'KPI_ORG',
  KPI_DEPARTMENT = 'KPI_DEPARTMENT',
  PROGREES_LIST = 'PROGREES_LIST',
  KPI_EMP_OF_UNIT = 'KPI_EMP_OF_UNIT',
  KPI_CONFIRM_TARGET = 'KPI_CONFIRM_TARGET',
  KPI_MANAGER_EVALUATE_EMP = 'KPI_MANAGER_EVALUATE_EMP',
  KPI_MANAGE_EMP = 'KPI_MANAGE_EMP',
  ONB_UPDATE_EMP_CODE = 'ONB_UPDATE_EMP_CODE',
  KPI_PERSONAL_EVALUATION = 'KPI_PERSONAL_EVALUATION',
  KPI_UNIT_EVALUATION = 'KPI_UNIT_EVALUATION',
  KPI_DIRECT_INDIRECT_LIST = 'KPI_DIRECT_INDIRECT_LIST',
  RATING_RULE = 'RATING_RULE',
  TRANSFORM_EMP_CODE = 'TRANSFORM_EMP_CODE',
  KPI_ASSESSMENT_PERIOD_MANAGEMENT = 'KPI_ASSESSMENT_PERIOD_MANAGEMENT',
  KPI_CONFIG = 'KPI_CONFIG',
  ESTABLISH_KPIS = 'ESTABLISH_KPIS',
  DELIVERY_EVALUATE_UNIT = 'DELIVERY_EVALUATE_UNIT',
  FAMILY_DEDUCTION = 'FAMILY_DEDUCTION',
  KPI_SET_LIST = 'KPI_SET_LIST',
  CONFIG_HUMAN_RESOURCES = 'CONFIG_HUMAN_RESOURCES',
  FN_COMPANY_INTEREST_RATE = 'FN_COMPANY_INTEREST_RATE',
  TAX_COMMITMENT = 'TAX_COMMITMENT',
  DOA_HR = 'DOA_HR',
  LABOR_RELATIONS_SETTING = 'LABOR_RELATIONS_SETTING',
  RATING_TITLE_UNIT = 'RATING_TITLE_UNIT',
  RATING_RULE_XL = 'RATING_RULE_XL',
  KPI_UNIT_LEADER = 'KPI_UNIT_LEADER',
  SALARY_REGULATION = 'SALARY_REGULATION',
  KPI_EVALUATE_APPELLATION = 'KPI_EVALUATE_APPELLATION',
  CONFIG_SALARY = 'CONFIG_SALARY',
  KPI_HRBP_EVALUATE_APPELLATION = 'KPI_HRBP_EVALUATE_APPELLATION',
  SALARY_LEVEL_CONFIG = 'SALARY_LEVEL_CONFIG',
  SALARY_CONFIGURATION = 'SALARY_CONFIGURATION',
  SYNCHRONIZATION_STATE = 'SYNCHRONIZATION_STATE',
  ONB_HISTORY_LIST = 'ONB_HISTORY_LIST',
  LIST_HIERARCHY_INTERNAL = 'LIST_HIERARCHY_INTERNAL',
  USER_PERMISSION = 'USER_PERMISSION',
  SALARY_ALLOWANCE_CONFIG = 'SALARY_ALLOWANCE_CONFIG',
  PAYSLIP_YOURSELF = 'PAYSLIP_YOURSELF',
  FULL_DATA_EMPLOYEE_REPORT = 'FULL_DATA_EMPLOYEE_REPORT',
  CONFIG_EMP = 'CONFIG_EMP',
  DOCUMENT_REGULATION = 'DOCUMENT_REGULATION',
  REGULATION = 'REGULATION',
  KPI_MANAGE_CEO = 'KPI_MANAGE_CEO',
  UNIT_MANAGE_LIST = 'UNIT_MANAGE_LIST',
}

export declare enum NetworkStatus {
  loading = 1,
  setVariables = 2,
  fetchMore = 3,
  refetch = 4,
  poll = 6,
  ready = 7,
  error = 8,
}

export enum MessageType {
  Error = 0,
  Success = 1,
  Warning = 2,
}

export enum SelfServiceScreen {
  TTCN = 'TTCN', // thông tin cá nhân
  TTLL = 'TTLL', // Thông tin liên lạc
  VISA = 'VISA', // Visa passport
  DEGREE = 'DEGREE', // Bằng cấp
  CERTIFICATE = 'CERTIFICATE', // chứng chỉ
  WORKING_PROCESS_MC = 'WORKING_PROCESS_MC', // thông tin khác
  OTHER_INFO = 'OTHER_INFO', // thông tin khác
}

export enum SelfServiceStatus {
  PENDING, // chờ duyệt
  WITHDRAW, // rút yêu cầu phê duyệt
  APPROVED, // đã phê duyệt
  CANCEL, // từ chối phê duyệt
  SENDNOTAPPROVED, // gửi duyệt không lưu
}

export enum SelfServiceObj {
  ID,
  APPROVALTAB,
}

export enum ResignType {
  KHAC = 'KHAC',
  CLCTV = 'CLCTV',
  XINNGHI = 'XINNGHI',
  TUY = 'TUY',
  KHTVC = 'KHTVC',
}

export const FILE_EXTENSION = {
  img: ['jpg', 'jpeg', 'png'],
  pdf: ['pdf'],
  excel: ['xls', 'xlsx'],
};

export const MENU = [
  {
    title: 'Trang chủ',
    link: '/dashboard',
    icon: 'mci-home',
    code: FunctionCode.DASHBOARD,
  },
  {
    title: 'Tổ chức',
    icon: 'mci-organization_new',
    children: [
      {
        title: 'Chuyển đổi mô hình tổ chức',
        icon: 'mci-home',
        code: FunctionCode.TRANSFER_ORG_MODEL,
        children: [
          {
            title: 'Mô hình tổ chức dự kiến',
            link: '/organization/structure-expected',
            icon: 'mci-home',
            code: FunctionCode.EXPECTED_ORG,
          },
          {
            title: 'Phương án luân chuyển nhân sự',
            link: '/organization/transfer-of-personnel/list',
            code: FunctionCode.DRAFT_TRANSFER_OF_PERSONNEL,
          },
          {
            title: 'Sơ đồ tổ chức dự kiến',
            link: '/organization/org-tree-draft',
            code: FunctionCode.ORG_TREE_DRAFT,
          },
          {
            title: 'Vị trí công việc dự kiến',
            link: '/organization/job-draft',
            code: FunctionCode.JOB_DRAFT,
          },
        ],
      },
      {
        title: 'Sơ đồ tổ chức',
        link: '/organization/tree',
        code: FunctionCode.ORG_CHART,
      },

      {
        title: 'Mô hình tổ chức',
        link: '/organization/model',
        code: FunctionCode.ORG_MODEL,
      },
      {
        title: 'Đơn vị',
        link: '/organization/unit',
        code: FunctionCode.MANAGEMENT_UNIT,
      },
      {
        title: 'Vị trí công việc',
        link: '/organization/job',
        code: FunctionCode.JOB,
      },

      {
        title: 'QL Mô tả công việc',
        link: '/organization/job-description',
        code: FunctionCode.JOB_DESCRIPTION,
      },

      {
        title: 'Thông tin doanh nghiệp',
        link: '/organization/profile',
        code: FunctionCode.BUSINESS_INFO,
      },

      {
        title: 'Danh mục',

        children: [
          {
            title: 'Vị trí',
            link: '/organization/location/list',
            code: FunctionCode.ORG_POSITION,
          },
          {
            title: 'Chức danh',
            link: '/organization/title-import/list',
            code: FunctionCode.ORG_TITLE,
          },
          {
            title: 'Trụ sở làm việc',
            link: '/organization/work-place',
            code: FunctionCode.WORK_LOCATION,
          },
        ],
      },
    ],
  },
  {
    title: 'Thông tin cá nhân',
    titleDisplay: 'Thông tin cá nhân',
    link: '/employees/employee-infor',
    icon: 'mci-employee',
    code: FunctionCode.EMP_INFO,
  },
  {
    title: 'Onboarding',
    icon: 'mci-onboarding',
    children: [
      {
        title: 'DS onboarding',
        link: '/onboarding/candidate-list',
        code: FunctionCode.OBN_LIST,
      },
      {
        title: 'DS duyệt thông tin UV',
        link: '/onboarding/information-browsing-list',
        code: FunctionCode.OBN_CANDIDATE_INFO_LIST,
      },
      {
        title: 'DS chuyển UV thành NV',
        link: '/onboarding/list-received',
        code: FunctionCode.TRANS_EMP_LIST,
      },
      {
        title: 'DS tạo tài khoản NV',
        link: '/onboarding/it-prepare-onb',
        code: FunctionCode.CREATE_ACC_LIST,
      },
      {
        title: 'DS chuẩn bị onboarding',
        link: '/onboarding/list-to-receive',
        code: FunctionCode.PREPARE_OBN_LIST,
      },
      {
        title: 'Báo cáo DS onboard',
        link: '/onboarding/utilities/full-data-onb',
        code: FunctionCode.REPORT_LIST,
      },
      {
        title: 'Lịch sử Onboarding',
        link: '/onboarding/onboarding-history',
        code: FunctionCode.ONB_HISTORY_LIST,
      },
      {
        title: 'Cấu hình',
        children: [
          {
            title: 'Hướng dẫn onboarding',
            link: '/onboarding/guide',
            code: FunctionCode.OBN_GUIDE,
          },
          {
            title: 'Cấu hình admin đơn vị duyệt thông tin ứng viên',
            link: '/employees/settings/admin-configuration',
            code: FunctionCode.CONFIG_ADMIN,
          },
        ],
      },
    ],
  },
  {
    title: 'Tổ chức nhân sự',
    titleDisplay: 'Tổ chức nhân sự',
    icon: 'mci-kehoachnhansu',
    children: [
      {
        title: 'Nhân sự',
        link: '/employees/employees-list',
        code: FunctionCode.EMP_LIST,
      },
      {
        title: 'Quá trình làm việc tại LPTech',
        link: '/employees/list-working-process',
        code: FunctionCode.WORKING_PROCESS_MC,
      },
      {
        title: 'Kết quả đánh giá',
        link: '/employees/list-result-review',
        code: FunctionCode.RESULT_REVIEW,
      },
      {
        title: 'Kỷ luật',
        link: '/employees/discipline-list',
        code: FunctionCode.DISCIPLINE_LIST,
      },
      {
        title: 'Nơi làm việc',
        link: '/employees/workplace',
        code: FunctionCode.WORKPLACE,
      },
      {
        title: 'Tiện ích',
        code: FunctionCode.EXTENSION,
        children: [
          {
            title: 'Chuyển mã nhân viên',
            link: '/employees/transform-employee-code',
            code: FunctionCode.TRANSFORM_EMP_CODE,
          },
          {
            title: 'Cập nhật mã NV cơ sở',
            link: '/onboarding/update-emp-code',
            code: FunctionCode.ONB_UPDATE_EMP_CODE,
          },
          {
            title: 'Cấu hình đầu mối nhân sự',
            link: '/organization/configuring-HR-focal-point/list',
            code: FunctionCode.CONFIG_HUMAN_RESOURCES,
          },
          {
            title: 'Sổ Văn Bản',
            link: '/employees/termination/text-book',
            code: FunctionCode.DOCUMENT_BOOK,
          },
        ],
      },
      {
        title: 'Báo cáo',
        code: FunctionCode.EXTENSION,
        children: [
          {
            title: 'Báo cáo full data',
            link: '/employees/full-data-report',
            code: FunctionCode.FULL_DATA_EMPLOYEE_REPORT,
          },
        ],
      },
      {
        title: 'DS quản lý đơn vị',
        link: '/employees/list-management-units',
        code: FunctionCode.UNIT_MANAGE_LIST,
      },
    ],
  },
  {
    title: 'Duyệt thông tin',
    titleDisplay: 'Duyệt thông tin nhân sự',
    icon: 'mci-duyetthongtin_nhansu',
    children: [
      {
        title: 'Duyệt thông tin nhân sự',
        link: '/employees/approval-request-list',
        code: FunctionCode.APPROVAL_REQUEST_LIST,
      },
      {
        title: 'Đơn vị duyệt đăng ký mượn hồ sơ',
        link: '/employees/online-filing-cabinet/browser-unit',
        code: FunctionCode.APPROVAL_REGIS_UNIT,
      },
      {
        title: 'HR duyệt đăng ký mượn hồ sơ',
        link: '/employees/online-filing-cabinet/browser-hr',
        code: FunctionCode.APPROVAL_REGIS_HR,
      },
      {
        title: 'Duyệt đơn nghỉ',
        link: '/timesheet/approve-leave-of-absence',
        code: FunctionCode.APPROVE_LEAVE_OF_ABSENCE,
      },
      {
        title: 'Duyệt bảng công',
        link: '/timesheet/approve-timesheets',
        code: FunctionCode.APPROVE_TIMESHEETS,
      },
      {
        title: 'Duyệt bảng công backdate',
        link: '/timesheet/approve-timesheets-backdate',
        code: FunctionCode.APPROVE_TIMESHEETS_BACKDATE,
      },
    ],
  },
  {
    title: 'Tủ hồ sơ',
    icon: 'mci-tuhoso_online',
    titleDisplay: 'Tủ hồ sơ online',
    children: [
      {
        title: 'Thông tin hồ sơ',
        link: 'employees/online-filing-cabinet/profile-information',
        code: FunctionCode.PROFILE_INFORMATION,
      },
      {
        title: 'Báo cáo thiếu đủ hồ sơ',
        link: '/employees/online-filing-cabinet/personnel-record-summary',
        code: FunctionCode.LESS_REPORT_FILE,
      },
      {
        title: 'Báo cáo thiếu đủ hồ sơ chi tiết',
        link: '/employees/online-filing-cabinet/personnel-record-summary-detail',
        code: FunctionCode.LESS_REPORT_DETAIL_FILE,
      },
      {
        title: 'Theo dõi mượn trả hồ sơ',
        link: '/employees/online-filing-cabinet/borrow-and-return-documents',
        code: FunctionCode.FOLLOW_FILE,
      },
      {
        title: 'Đăng ký mượn hồ sơ',
        link: '/employees/online-filing-cabinet/borrow-employee-files',
        code: FunctionCode.REGIS_BORROW,
      },
      {
        title: 'Cấu hình',

        code: FunctionCode.CONFIG_EMP,
        children: [
          {
            title: 'Danh mục hồ sơ',
            link: 'employees/online-filing-cabinet/profile-list',
            code: FunctionCode.DOCUMENT_REGULATION,
          },
          {
            title: 'Quy định hồ sơ',
            link: 'employees/online-filing-cabinet/profile-regulations',
            code: FunctionCode.REGULATION,
          },
        ],
      },
    ],
  },
  {
    title: 'Thông tin lao động',
    titleDisplay: 'Quan hệ lao động',
    icon: 'mci-qhe_laodong',
    children: [
      {
        title: 'Quản lý hợp đồng',
        children: [
          {
            title: 'DS hợp đồng',
            link: '/employees/contract-management/list-contract',
            code: FunctionCode.CONTRACT_OPERATION,
          },
          {
            title: 'Đến hạn hợp đồng',
            link: '/employees/contract-management/contract-expiration',
            code: FunctionCode.CONTRACT_EXPIRATION,
          },
          {
            title: 'DS Đánh giá tái ký',
          },
          {
            title: 'Đề xuất chuyển loại',
            link: '/employees/contract-management/change-type-contract',
            code: FunctionCode.OFFER_TYPE_TRANS,
          },
          {
            title: 'Danh sách đánh giá hợp đồng',
            link: '/employees/contract-management/contract-self-assessment',
            code: FunctionCode.CONTRACT_SELF_ASSESSMENT,
          },
          {
            title: 'Tái ký hợp đồng',
            link: '/employees/contract-management/contract-assessment-list',
            code: FunctionCode.RENEWS_CONTRACT,
          },
          {
            title: 'Uỷ quyền hợp đồng',
            link: '/employees/contract-management/contract-authorization',
            code: FunctionCode.CONTRACT_AUTHORIZATION,
          },
          {
            title: 'Tự đánh giá hợp đồng',
            link: '/employees/contract-management/contract-self-assessment',
            code: FunctionCode.CONTRACT_SELF_ASSESSMENT_ACTIVE,
          },
          {
            title: 'Loại hợp đồng',
            link: '/employees/category-common/contract',
            code: FunctionCode.CONTRACT_CATEGORY,
          },
        ],
      },
      {
        title: 'Thôi việc',
        children: [
          {
            title: 'Đơn thôi việc',
            link: '/employees/termination/resign-form',
            code: FunctionCode.RESIGNATION,
          },
          {
            title: 'Đơn vị báo nghỉ',
            link: '/employees/termination/resignation-letter',
            code: FunctionCode.RESIGN_REMEMBER,
          },
          {
            title: 'Thôi việc',
            link: '/employees/termination/approve-resignation-letter',
            code: FunctionCode.RESIGN_LIST,
          },
          {
            title: 'Xác nhận nghĩa vụ thôi việc',
            link: '/employees/termination/resign-obligation',
            code: FunctionCode.DUTY_LIST,
          },
          {
            title: 'Tiếp nhận bàn giao công việc',
            link: '/employees/termination/confirm-tranfer-work',
            code: FunctionCode.RECEIVE_HAND_OVER_LIST,
          },
          {
            title: 'Xác nhận bàn giao công việc',
            link: '/employees/termination/manager-confirm',
            code: FunctionCode.CONFIRM_HAND_OVER,
          },
          {
            title: 'Vận hành thôi việc',
            link: '/employees/termination/operate',
            code: FunctionCode.OPERATE_RESIGN_LIST,
          },
          {
            title: 'DS KQ chạy job QTLV',
            link: '/employees/termination/results-transfer-status-leave',
            code: FunctionCode.TRANSFER_RESULT,
          },
          {
            title: 'Sổ Văn Bản',
            link: '/employees/termination/text-book',
            code: FunctionCode.DOCUMENT_BOOK,
          },
        ],
      },
      {
        title: 'Cấu hình',
        code: FunctionCode.LABOR_RELATIONS_SETTING,
        children: [
          {
            title: 'Quản lý DOA hoạt động nhân sự',
            link: '/employees/doa/list',
            code: FunctionCode.DOA_HR,
          },
          {
            title: 'Chỉ tiêu đánh giá hợp đồng',
            link: '/employees/settings/contract-criteria',
            code: FunctionCode.ASSESSMENT_CRITERIA,
          },
          {
            title: 'Kết luận đánh giá hợp đồng',
            link: '/employees/settings/contract-conclusion',
            code: FunctionCode.CONTRACT_CONCLUSION_ASSESSMENT,
          },
        ],
      },
    ],
  },
  {
    title: 'Chấm công',

    icon: 'mci-timesheet',
    code: FunctionCode.TIMESHEET,
    children: [
      {
        title: 'Lịch phân công trực đơn vị',
        link: '/timesheet/assign-off-schedule',
        code: FunctionCode.ASSIGN_OFF_SCHEDULE,
      },
      {
        title: 'Đơn nghỉ cá nhân',
        link: '/timesheet/register-off',
        code: FunctionCode.REGISTEER_OFF,
      },
      {
        title: 'Đơn nghỉ',
        link: '/timesheet/list-off',
        code: FunctionCode.LIST_OFF,
      },
      {
        title: 'Tổng hợp bảng công',
        link: '/timesheet/timesheets',
        code: FunctionCode.TIMESHEETS,
      },
      {
        title: 'Tổng hợp bảng công backdate',
        link: '/timesheet/timesheets-backdate',
        code: FunctionCode.TIMESHEETS_BACKDATE,
      },
      {
        title: 'Cấu hình',

        children: [
          {
            title: 'Lịch làm việc',
            link: '/timesheet/assign-work-schedule',
            code: FunctionCode.ASSIGN_WORK_SCHEDULE,
          },
          {
            title: 'Kỳ chấm công',
            link: 'timesheet/timekeeping-period',
            code: FunctionCode.TIMEKEEPING_PERIOD,
          },
          {
            title: 'Ngày nghỉ lễ ',
            link: '/timesheet/holiday-calender',
            code: FunctionCode.HOLIDAY_CALENDER,
          },
          {
            title: 'Nhân viên chưa thiết lập lịch làm việc',
            link: '/timesheet/establish-schedule-employee',
            code: FunctionCode.ESTABLISH_SCHEDULE_EMPLOYEE,
          },
        ],
      },
    ],
  },
  {
    title: 'Lương thưởng',
    code: FunctionCode.SALARY_BONUS,
    icon: 'mci-luongDN',
    children: [
      {
        title: 'Cấu hình',

        code: FunctionCode.CONFIG_SALARY,
        children: [
          {
            title: 'Mức lương',
            link: '/salary/salary-bonus-config/list',
            code: FunctionCode.SALARY_CONFIGURATION,
          },
          {
            title: 'Dải lương',
            link: '/salary/salary-range/list',
            code: FunctionCode.SALARY_RANGE,
          },
          {
            title: 'Quy chế lương',
            link: '/salary/regulation-salary/list',
            code: FunctionCode.SALARY_REGULATION,
          },
          {
            title: 'Cài đặt phụ cấp',
            link: '/salary/settings/salary-settings/allowance-settings',
          },
          {
            title: 'Tạo kỳ lương',
            link: '/salary/salary-period/list',
            code: FunctionCode.SALARY_PERIOD_CATEGORY,
          },
          {
            title: 'Công thức lương',
            link: '/salary/settings/salary-settings/salary-formula',
          },
          {
            title: 'Tỷ lệ THS theo KQ kinh doanh công ty',
            link: '/salary/company-interest/list',
            code: FunctionCode.FN_COMPANY_INTEREST_RATE,
          },
          {
            title: 'Chính sách dải lương',
            link: '/salary/salary-level-config/list',
            code: FunctionCode.SALARY_LEVEL_CONFIG,
          },
          {
            title: 'Chính sách phụ cấp',
            link: '/salary/allowance-policy/list',
            code: FunctionCode.SALARY_ALLOWANCE_CONFIG,
          },
          {
            title: 'Danh sách cấp bậc nội bộ',
            link: '/salary/internal-level/list',
            code: FunctionCode.LIST_HIERARCHY_INTERNAL,
          },
        ],
      },
      {
        title: 'Đầu vào lương',

        children: [
          {
            title: 'Diễn biến lương cơ bản, mức căn cứ tính thưởng',
            link: '/salary/bonus-base/personal/list',
            code: FunctionCode.BONUS_BASE_DEVELOPMENT,
          },
          {
            title: 'Diễn biến thù lao',
            link: '/salary/remuneration/single-list',
            code: FunctionCode.DEVELOPMENT_REMUNERATION_EMP,
          },
          {
            title: 'Diễn biến phụ cấp',
            link: '/salary/allowances/single-list',
            code: FunctionCode.DEVELOPMENT_OF_ACCESSORY,
          },
          {
            title: 'DS diễn biến LCB, MCCTT',
            link: '/salary/bonus-base/multiple/list',
            code: FunctionCode.BONUS_BASE_LIST,
          },
          {
            title: 'DS diễn biến thù lao',
            link: '/salary/remuneration/list',
            code: FunctionCode.REMUNERATION,
          },
          {
            title: 'DS diễn biến phụ cấp',
            link: '/salary/allowances/list',
            code: FunctionCode.ALLOWANCE_LIST,
          },
          {
            title: 'Thực thi chính sách phụ cấp',
            link: '/salary/implement-allowance-policy/list',
            code: FunctionCode.POLICY_IMPLEMENTATION,
          },
          {
            title: 'Điều chỉnh thu nhập',
            link: '/salary/allowances/adjustment',
            code: FunctionCode.INCOME_ADJUSTMENT,
          },
          {
            title: 'Bù trừ lương',
            link: '/salary/compensate-deduct/list',
            code: FunctionCode.SALARY_OFFSET,
          },
          {
            title: 'Trợ cấp thôi việc',
            link: '/salary/severance-allowance/list',
            code: FunctionCode.SEVERANCE_ALLOWANCE,
          },
        ],
      },
      {
        title: 'Lương tháng',

        children: [
          {
            title: 'Đợt lương',
            link: '/salary/batch-salary/list',
            code: FunctionCode.SALARY_PERIOD,
          },
          {
            title: 'Xem bảng lương',
            link: '/salary/payroll/list',
            code: FunctionCode.PAYROLL,
          },
          {
            title: 'Tính lương tháng',
            link: '/salary/calculation-salary-period/list',
            code: FunctionCode.CALCULATE_MONTH_SALARY,
          },
          {
            title: 'Phiếu lương',
            link: '/salary/payslip/list',
            code: FunctionCode.PAYSLIP,
          },
        ],
      },
      {
        title: 'Lương treo',

        code: FunctionCode.SALARY_CALCULATION,
        children: [
          {
            title: 'Danh sách lương treo',
            link: '/salary/hanging-salary/list',
            code: FunctionCode.SALARY_HOLDING,
          },
          {
            title: 'Quyết toán lương treo',
            link: 'salary/settlement-of-suspended-salary/list',
            code: FunctionCode.HOLDING_FINALIZATION,
          },
          {
            title: 'Thu hồi lương treo',
            link: '/salary/recall-confirmation/list',
            code: FunctionCode.SALARY_CALCULATION_RECOVERY,
          },
        ],
      },
      {
        title: 'Phiếu lương',
        link: '/salary/payslip/list-data',
        code: FunctionCode.PAYSLIP_YOURSELF,
      },

      {
        title: 'Diễn biến LCB, MCCTT',
        link: 'salary/evolution/personal/list',
        code: FunctionCode.YOURSELF_BONUS_BASE_DEVELOPMENT,
      },
      {
        title: 'Diễn biến thù lao',
        link: 'salary/remuneration-evolution/single-list',
        code: FunctionCode.YOURSELF_DEVELOPMENT_REMUNERATION_EMP,
      },
      {
        title: 'Diễn biến phụ cấp',
        link: 'salary/allowances-evolution/single-list',
        code: FunctionCode.YOURSELF_DEVELOPMENT_OF_ACCESSORY,
      },
    ],
  },

  {
    title: 'Kế toán lương',

    icon: 'mci-thue',
    children: [
      {
        title: 'Thuế thu nhập cá nhân',

        children: [
          {
            title: 'Thuế thu nhập cá nhân tháng',
            link: '/accountant/monthly-personal-income/list',
            code: FunctionCode.MONTHLY_PERSONAL_INCOME_TAX,
          },
          {
            title: 'Danh sách cam kết thuế',
            link: '/tax/tax-commitment/list',
            code: FunctionCode.TAX_COMMITMENT,
          },
          {
            title: 'Danh sách giảm trừ gia cảnh',
            link: '/accountant/family-deduction/list',
            code: FunctionCode.FAMILY_DEDUCTION,
          },
        ],
      },
    ],
  },
  {
    title: 'Bảo hiểm',

    icon: 'mci-timesheet',
    children: [
      {
        title: 'Bảo hiểm bắt buộc',

        children: [
          {
            title: 'Thông tin đóng bảo hiểm',
            link: '/insurance/insurance-payment-information/list',
            code: FunctionCode.INSURANCE_INFO,
          },
          {
            title: 'Diễn biến lương cơ bản',
            link: '/insurance/salary-evolution',
            code: FunctionCode.SALARY_EVOLUTION,
          },
          {
            title: 'Báo tăng bảo hiểm',
            link: '/insurance/insurance-increase-report/list',
            code: FunctionCode.INSURANCE_UP,
          },
          {
            title: 'Báo giảm bảo hiểm',
            link: '/insurance/reduce-insurance-report/list',
            code: FunctionCode.INSURANCE_DOWN,
          },
          {
            title: 'Người lao động kê khai',
            link: '/insurance/laborers-declaration/list',
            code: FunctionCode.INSURANCE_DECLARE_LIST,
          },
          {
            title: 'Điều chỉnh bảo hiểm',
            link: '/insurance/insurance-adjustment/list',
            code: FunctionCode.INSURANCE_ADJUSTMENT,
          },
          {
            title: 'DS đóng bảo hiểm',
            link: '/insurance/take-insuarance/list',
            code: FunctionCode.TAKE_INSURANCE,
          },
          {
            title: 'Trích nộp',
            link: '/insurance/deduction/list',
            code: FunctionCode.DEDUCTION,
          },
        ],
      },
      {
        title: 'Kê khai tham gia bảo hiểm',
        link: 'insurance/insurance-information/labor-insurance-regist',
        code: FunctionCode.INSURANCE_DECLARATION,
      },

      {
        title: 'Thẻ BHYT',
      },
      {
        title: 'Sổ BHXH',
      },
      {
        title: 'Quá trình đóng BH',
      },
      {
        title: 'Báo cáo',
      },
    ],
  },

  {
    title: 'Quản trị thành tích',

    icon: 'mci-kpi',
    code: FunctionCode.KPIS,
    children: [
      {
        title: 'Giao mục tiêu CBNV',

        code: FunctionCode.KPI_PERSONAL,
        children: [
          {
            title: 'DS giao mục tiêu trực tiếp',
            link: 'kpi/kpi-bottom/list',
            code: FunctionCode.KPI_BOTTOM,
          },
          {
            title: 'DS giao mục tiêu CBNV trong đơn vị',
            link: 'kpi/kpi-bottom/list-employee',
            code: FunctionCode.KPI_EMP_OF_UNIT,
          },
        ],
      },
      {
        title: 'Đánh giá công ty',

        code: FunctionCode.KPI_ORG,
        children: [
          {
            title: 'Mục tiêu công ty',
            link: 'kpi/kpis-company/list',
            code: FunctionCode.KPIS_COMPANY,
          },
        ],
      },

      {
        title: 'DS đánh giá cá nhân',
        link: 'kpi/personal-evaluation/list',
        code: FunctionCode.KPI_PERSONAL_EVALUATION,
      },

      {
        title: 'DS giao mục tiêu cá nhân',
        link: 'kpi/kpi-confirm-target/list',
        code: FunctionCode.KPI_CONFIRM_TARGET,
      },
      {
        title: 'Giao - Đánh giá đơn vị',

        code: FunctionCode.DELIVERY_EVALUATE_UNIT,
        children: [
          {
            title: 'DS đánh giá đơn vị',
            link: 'kpi/unit-evaluation/list',
            code: FunctionCode.KPI_UNIT_EVALUATION,
          },
          {
            title: 'Giao mục tiêu đơn vị',
            link: 'kpi/kpi-unit/list',
            code: FunctionCode.KPI_UNIT,
          },
          {
            title: 'Xếp loại & danh hiệu đơn vị',
            link: 'kpi/rating-title-unit/list',
            code: FunctionCode.RATING_TITLE_UNIT,
          },
        ],
      },

      {
        title: 'Đánh giá CBNV',

        code: FunctionCode.KPI_MANAGE_EMP,
        children: [
          {
            title: 'DS đánh giá CBNV',
            link: 'kpi/manager-evaluate/list',
            code: FunctionCode.KPI_MANAGER_EVALUATE_EMP,
          },
          {
            title: 'DS đánh giá trực tiếp - gián tiếp',
            link: 'kpi/direct-indirect-evaluation/list',
            code: FunctionCode.KPI_DIRECT_INDIRECT_LIST,
          },
          {
            title: 'Phân bổ xếp loại & danh hiệu',
            link: 'kpi/manager-evaluate/evaluate-appellation',
            code: FunctionCode.KPI_EVALUATE_APPELLATION,
          },
          {
            title: 'Đơn vị duyệt PBXL & danh hiệu',
            link: 'kpi/personal-evaluation/unit-leader-list',
            code: FunctionCode.KPI_UNIT_LEADER,
          },
          {
            title: 'Hrbp phân bổ xếp loại & danh hiệu',
            link: 'kpi/manager-evaluate/hrbp-evaluate-appellation',
            code: FunctionCode.KPI_HRBP_EVALUATE_APPELLATION,
          },
          {
            title: 'DS đánh giá CEO-1',
            link: 'kpi/ceo-assessment/list',
            code: FunctionCode.KPI_MANAGE_CEO,
          },
        ],
      },

      {
        title: 'Cấu hình',

        code: FunctionCode.KPI_CONFIG,
        children: [
          {
            title: 'DS quản lý kỳ đánh giá',
            link: 'kpi/assessment-period-management/list',
            code: FunctionCode.KPI_ASSESSMENT_PERIOD_MANAGEMENT,
          },
          {
            title: 'DS bộ KPIs',
            link: 'kpi/assessment-period-management/list-of-kpi',
            code: FunctionCode.KPI_SET_LIST,
          },
          {
            title: 'Rule Đánh giá',
            link: 'kpi/setting/ratting-rule',
            code: FunctionCode.RATING_RULE,
          },
          {
            title: 'Rule xếp loại',
            link: 'kpi/rule-rating/list',
            code: FunctionCode.RATING_RULE_XL,
          },
        ],
      },
    ],
  },

  {
    title: 'Cài đặt',

    icon: 'mci-setting',
    code: FunctionCode.ADMINISTRATION,
    children: [
      {
        title: 'Danh mục',

        code: FunctionCode.CATEGORY_ADMIN,
      },
      {
        title: 'Master data',

        code: FunctionCode.MASTER_DATA,
      },
      {
        title: 'Chuyển đổi dữ liệu (Migrate data)',

        children: [
          {
            title: 'Chuyển đổi dữ liệu nhân sự',
            link: 'setting/master-data/migrate-emp',
            code: FunctionCode.MIGRATE_EMP,
          },
          {
            title: 'Chuyển đổi dữ liệu bảo hiểm',
            link: 'setting/master-data/migrate-ins',
            code: FunctionCode.MIGRATE_INS,
          },
          {
            title: 'Chuyển đổi dữ liệu lương',
            link: 'setting/master-data/migrate-sl',
            code: FunctionCode.MIGRATE_SL,
          },
          {
            title: 'Chuyển đổi dữ liệu tổ chức',
            link: 'setting/master-data/migrate-org',
            code: FunctionCode.MIGRATE_ORG,
          },
          {
            title: 'Chuyển đổi dữ liệu thuế',
            link: 'setting/master-data/migrate-tax',
            code: FunctionCode.MIGRATE_TAX,
          },
        ],
      },
      {
        title: 'Tài khoản',
        link: '/setting/master-data/user',
        code: FunctionCode.ACCOUNT,
      },
      {
        title: 'Nhóm người dùng',
        link: '/setting/master-data/role',
        code: FunctionCode.USER_GROUP,
      },
      {
        title: 'Phân quyền',
        link: '/setting/master-data/decentralization',
        code: FunctionCode.USER_PERMISSION,
      },
      {
        title: 'Migrate-data',
        link: '/setting/master-data/migrate-actionData',
        code: FunctionCode.USER_PERMISSION,
      },
      {
        title: 'Phân miền dữ liệu',
        link: '/setting/master-data/user-permission',
        code: FunctionCode.PERMISSION_DATA,
      },
      {
        title: 'Quản lý template email, SMS',

        code: FunctionCode.TEMPLATE_MANAGE,
      },
      {
        title: 'Cài đặt chức năng',

        code: FunctionCode.CONFIG,
        children: [
          {
            title: 'Danh sách gán quyền quản lý trực tiếp',
            link: '/employees/settings/list-assign-direct-management',
            code: FunctionCode.ASSIGNMENT_OF_MANAGEMENT_LIST,
          },
          {
            title: 'Gán quyền quản lý trực tiếp',
            link: '/employees/settings/assign-direct-management',
            code: FunctionCode.ASSIGNMENT_OF_MANAGEMENT,
          },
          {
            title: 'Cấu hình CEO phê duyệt ĐGHĐ, thôi việc',
            link: 'employees/settings/approve-ceo',
            code: FunctionCode.CONFIG_CEO,
          },
          {
            title: 'Cấu hình kiểm soát HR',
            link: 'employees/settings/control-hr',
            code: FunctionCode.CONFIG_HR,
          },
          {
            title: 'Cấu hình đầu mối dịch vụ nhân viên',
            link: '/employees/settings/configure-service-staff',
            code: FunctionCode.CONFIG_SERVICE,
          },
          {
            title: 'Khảo sát thôi việc',
            link: '/employees/termination/severance-survey',
            code: FunctionCode.SERVEY,
          },
          {
            title: 'Cài đặt mẫu hợp đồng',
            link: '/employees/contract-management/contract-template-settings',
            code: FunctionCode.CONTRACT_FORM,
          },
        ],
      },
      {
        title: 'Cài đặt dữ liệu',

        code: FunctionCode.CONFIG_DATA,
        children: [
          {
            title: 'Quốc tịch',
            link: '/setting/master-data/nationality',
            code: FunctionCode.NATIONALITY,
          },
          {
            title: 'Dân tộc',
            link: '/setting/master-data/ethnic',
            code: FunctionCode.ETHNIC,
          },
          {
            title: 'Tôn giáo',
            link: '/setting/master-data/religion',
            code: FunctionCode.RELIGION,
          },
          {
            title: 'Ngân hàng',
            link: '/setting/master-data/bank',
            code: FunctionCode.BANK,
          },
          {
            title: 'Chuyên ngành',
            link: '/setting/master-data/major',
            code: FunctionCode.SPECIALIZED,
          },
          {
            title: 'Chứng chỉ',
            link: '/setting/master-data/certificate',
            code: FunctionCode.CERTIFICATE,
          },
          {
            title: 'Ngành nghề',
            link: '/setting/master-data/industry',
            code: FunctionCode.CAREER,
          },
          {
            title: 'Khu vực',
            link: '/setting/master-data/area',
            code: FunctionCode.AREA,
          },
          {
            title: 'Nhóm',
            link: '/setting/master-data/group',
            code: FunctionCode.GROUP,
          },
          {
            title: 'Nhóm năng lực',
            link: '/setting/master-data/competence-group',
            code: FunctionCode.CAPACITY_GROUP,
          },
          {
            title: 'Từ điển năng lực',
            link: '/setting/master-data/competence-dictionary',
            code: FunctionCode.ABILITY,
          },
        ],
      },
      {
        title: 'Nhóm danh mục dùng chung',
        link: '/setting/master-data/common-category',
        code: FunctionCode.COMMON_CATEGORY,
      },
      {
        title: 'Quản lý tiến trình',
        link: '/setting/master-data/process-list',
        code: FunctionCode.PROGREES_LIST,
      },
      {
        title: 'Trạng thái đồng bộ',
        link: '/setting/master-data/synchronization-state',
        code: FunctionCode.SYNCHRONIZATION_STATE,
      },
    ],
  },
];

export const MENU_EMPLOYEE = [
  {
    title: 'Trang chủ',
    link: '/dashboard',
    icon: 'mci-home',
    code: FunctionCode.DASHBOARD,
  },
  {
    title: 'Tổ chức',
    icon: 'mci-organization_new',
    children: [
      {
        title: 'Sơ đồ tổ chức',
        link: '/organization/tree',
        code: FunctionCode.ORG_CHART,
      },
      {
        title: 'Mô hình tổ chức',
        link: '/organization/model',
        code: FunctionCode.ORG_MODEL,
      },
      {
        title: 'Sơ đồ tổ chức dự kiến',
        link: '/organization/org-tree-draft',
        code: FunctionCode.ORG_TREE_DRAFT,
      },
      {
        title: 'Vị trí công việc',
        link: '/organization/job',
        code: FunctionCode.JOB,
      },
      {
        title: 'QL Mô tả công việc',
        link: '/organization/job-description',
        code: FunctionCode.JOB_DESCRIPTION,
      },
      {
        title: 'Trụ sở làm việc',
        link: '/organization/work-place',
        code: FunctionCode.WORK_LOCATION,
      },
      {
        title: 'Thông tin doanh nghiệp',
        link: '/organization/profile',
        code: FunctionCode.BUSINESS_INFO,
      },

      {
        title: 'Danh mục',

        children: [
          {
            title: 'Vị trí',
            link: '/organization/location/list',
            code: FunctionCode.ORG_POSITION,
          },
          {
            title: 'Chức danh',
            link: '/organization/title-import/list',
            code: FunctionCode.ORG_TITLE,
          },
          {
            title: 'Trụ sở làm việc',
            link: '/organization/work-place',
            code: FunctionCode.WORK_LOCATION,
          },
        ],
      },
    ],
  },
  {
    title: 'Thông tin cá nhân',
    titleDisplay: 'Thông tin nhân sự',
    link: '/employees/employee-infor',
    icon: 'mci-employee',
    code: FunctionCode.EMP_INFO,
  },
  {
    title: 'Onboarding',
    icon: 'mci-onboarding',
    children: [
      {
        title: 'DS onboarding',
        link: '/onboarding/candidate-list',
        code: FunctionCode.OBN_LIST,
      },
      {
        title: 'DS duyệt thông tin UV',
        link: '/onboarding/information-browsing-list',
        code: FunctionCode.OBN_CANDIDATE_INFO_LIST,
      },
      {
        title: 'DS chuyển UV thành NV',
        link: '/onboarding/list-received',
        code: FunctionCode.TRANS_EMP_LIST,
      },
      {
        title: 'DS tạo tài khoản NV',
        link: '/onboarding/it-prepare-onb',
        code: FunctionCode.CREATE_ACC_LIST,
      },
      {
        title: 'DS chuẩn bị onboarding',
        link: '/onboarding/list-to-receive',
        code: FunctionCode.PREPARE_OBN_LIST,
      },
      {
        title: 'Báo cáo DS onboard',
        link: '/onboarding/utilities/full-data-onb',
        code: FunctionCode.REPORT_LIST,
      },
      {
        title: 'Lịch sử Onboarding',
        link: '/onboarding/onboarding-history',
        code: FunctionCode.ONB_HISTORY_LIST,
      },
      {
        title: 'Cấu hình',
        children: [
          {
            title: 'Hướng dẫn onboarding',
            link: '/onboarding/guide',
            code: FunctionCode.OBN_GUIDE,
          },
          {
            title: 'Cấu hình admin đơn vị duyệt thông tin ứng viên',
            link: '/employees/settings/admin-configuration',
            code: FunctionCode.CONFIG_ADMIN,
          },
        ],
      },
    ],
  },
  {
    title: 'Duyệt thông tin',
    titleDisplay: 'Duyệt thông tin nhân sự',
    icon: 'mci-duyetthongtin_nhansu',
    children: [
      {
        title: 'Duyệt thông tin nhân sự',
        link: '/employees/approval-request-list',
        code: FunctionCode.APPROVAL_REQUEST_LIST,
      },
      {
        title: 'Đơn vị duyệt đăng ký mượn hồ sơ',
        link: '/employees/online-filing-cabinet/browser-unit',
        code: FunctionCode.APPROVAL_REGIS_UNIT,
      },
      {
        title: 'HR duyệt đăng ký mượn HS',
        link: '/employees/online-filing-cabinet/browser-hr',
        code: FunctionCode.APPROVAL_REGIS_HR,
      },
    ],
  },
  {
    title: 'Hợp đồng',

    titleDisplay: 'Quản lý hợp đồng',
    icon: 'mci-qhe_laodong',
    children: [
      {
        title: 'DS hợp đồng',
        link: '/employees/contract-management/list-contract',
        code: FunctionCode.CONTRACT_OPERATION,
      },
      {
        title: 'Đến hạn hợp đồng',
        link: '/employees/contract-management/contract-expiration',
        code: FunctionCode.CONTRACT_EXPIRATION,
      },
      {
        title: 'DS Đánh giá tái ký',
      },
      {
        title: 'Đề xuất chuyển loại',
        link: '/employees/contract-management/change-type-contract',
        code: FunctionCode.OFFER_TYPE_TRANS,
      },
      {
        title: 'Tái ký hợp đồng',
        link: '/employees/contract-management/contract-assessment-list',
        code: FunctionCode.RENEWS_CONTRACT,
      },
      {
        title: 'Uỷ quyền hợp đồng',
        link: '/employees/contract-management/contract-authorization',
        code: FunctionCode.CONTRACT_AUTHORIZATION,
      },
      {
        title: 'Danh sách đánh giá hợp đồng',
        link: '/employees/contract-management/contract-self-assessment',
        code: FunctionCode.CONTRACT_SELF_ASSESSMENT,
      },
      {
        title: 'Tự đánh giá hợp đồng',
        link: '/employees/contract-management/contract-self-assessment',
        code: FunctionCode.CONTRACT_SELF_ASSESSMENT_ACTIVE,
      },
      {
        title: 'Loại hợp đồng',
        link: '/employees/category-common/contract',
        code: FunctionCode.CONTRACT_CATEGORY,
      },
    ],
  },

  {
    title: 'Chấm công',

    icon: 'mci-timesheet',
    code: FunctionCode.TIMESHEET,
    children: [
      {
        title: 'Cấu hình',

        children: [
          {
            title: 'Lịch làm việc',
            link: '/timesheet/assign-work-schedule',
            code: FunctionCode.ASSIGN_WORK_SCHEDULE,
          },
          {
            title: 'Kỳ chấm công',
            link: 'timesheet/timekeeping-period',
            code: FunctionCode.TIMEKEEPING_PERIOD,
          },
          {
            title: 'Ngày nghỉ lễ ',
            link: '/timesheet/holiday-calender',
            code: FunctionCode.HOLIDAY_CALENDER,
          },
          {
            title: 'Nhân viên chưa thiết lập lịch làm việc',
            link: '/timesheet/establish-schedule-employee',
            code: FunctionCode.ESTABLISH_SCHEDULE_EMPLOYEE,
          },
        ],
      },
      {
        title: 'Lịch phân công trực đơn vị',
        link: '/timesheet/assign-off-schedule',
        code: FunctionCode.ASSIGN_OFF_SCHEDULE,
      },
      {
        title: 'Danh sách đơn nghỉ',
        link: '/timesheet/register-off',
        code: FunctionCode.REGISTEER_OFF,
      },
      {
        title: 'Danh sách đơn nghỉ',
        link: '/timesheet/list-off',
        code: FunctionCode.LIST_OFF,
      },
      {
        title: 'Tạo đơn nghỉ',
        link: '/timesheet/register-off-create-from-home',
        code: FunctionCode.LIST_OFF_CREATE,
      },
      {
        title: 'Tổng hợp bảng công',
        link: '/timesheet/timesheets',
        code: FunctionCode.TIMESHEETS,
      },
      {
        title: 'Tổng hợp bảng công backdate',
        link: '/timesheet/timesheets-backdate',
        code: FunctionCode.TIMESHEETS_BACKDATE,
      },
    ],
  },
  {
    title: 'Lương thưởng',

    icon: 'mci-luongDN',
    children: [
      {
        title: 'Phiếu lương',
        link: '/salary/payslip/list-data',
        code: FunctionCode.PAYSLIP_YOURSELF,
      },
      {
        title: 'Diễn biến LCB, MCCTT',
        link: 'salary/evolution/personal/list',
        code: FunctionCode.YOURSELF_BONUS_BASE_DEVELOPMENT,
      },
      {
        title: 'Diễn biến thù lao',
        link: 'salary/remuneration-evolution/single-list',
        code: FunctionCode.YOURSELF_DEVELOPMENT_REMUNERATION_EMP,
      },
      {
        title: 'Diễn biến phụ cấp',
        link: 'salary/allowances-evolution/single-list',
        code: FunctionCode.YOURSELF_DEVELOPMENT_OF_ACCESSORY,
      },
    ],
  },
  {
    title: 'Kế toán lương',

    icon: 'mci-thue',
    children: [
      {
        title: 'Thuế thu nhập cá nhân',

        children: [
          {
            title: 'Thuế thu nhập cá nhân tháng',
            link: '/accountant/monthly-personal-income/list',
            code: FunctionCode.MONTHLY_PERSONAL_INCOME_TAX,
          },
          {
            title: 'Danh sách cam kết thuế',
            link: '/tax/tax-commitment/list',
            code: FunctionCode.TAX_COMMITMENT,
          },
          {
            title: 'Danh sách giảm trừ gia cảnh',
            link: '/accountant/family-deduction/list',
            code: FunctionCode.FAMILY_DEDUCTION,
          },
        ],
      },
    ],
  },
  {
    title: 'Bảo hiểm',

    icon: 'mci-timesheet',
    children: [
      {
        title: 'Bảo hiểm bắt buộc',

        children: [
          {
            title: 'Thông tin đóng bảo hiểm',
            link: '/insurance/insurance-payment-information/list',
            code: FunctionCode.INSURANCE_INFO,
          },
          {
            title: 'Diễn biến lương cơ bản',
            link: '/insurance/salary-evolution',
            code: FunctionCode.SALARY_EVOLUTION,
          },
          {
            title: 'Báo tăng bảo hiểm',
            link: '/insurance/insurance-increase-report/list',
            code: FunctionCode.INSURANCE_UP,
          },
          {
            title: 'Báo giảm bảo hiểm',
            link: '/insurance/reduce-insurance-report/list',
            code: FunctionCode.INSURANCE_DOWN,
          },
          {
            title: 'Người lao động kê khai',
            link: '/insurance/laborers-declaration/list',
            code: FunctionCode.INSURANCE_DECLARE_LIST,
          },
          {
            title: 'Điều chỉnh bảo hiểm',
            link: '/insurance/insurance-adjustment/list',
            code: FunctionCode.INSURANCE_ADJUSTMENT,
          },
          {
            title: 'DS đóng bảo hiểm',
            link: '/insurance/take-insuarance/list',
            code: FunctionCode.TAKE_INSURANCE,
          },
        ],
      },
      {
        title: 'Kê khai tham gia bảo hiểm',
        link: 'insurance/insurance-information/labor-insurance-regist',
        code: FunctionCode.INSURANCE_DECLARATION,
      },

      {
        title: 'Thẻ BHYT',
      },
      {
        title: 'Sổ BHXH',
      },
      {
        title: 'Quá trình đóng BH',
      },
      {
        title: 'Báo cáo',
      },
    ],
  },
  {
    title: 'Quản trị thành tích',

    icon: 'mci-kpi',
    code: FunctionCode.KPIS,
    children: [
      {
        title: 'Giao mục tiêu CBNV',

        code: FunctionCode.KPI_PERSONAL,
        children: [
          {
            title: 'DS giao mục tiêu trực tiếp',
            link: 'kpi/kpi-bottom/list',
            code: FunctionCode.KPI_BOTTOM,
          },
          {
            title: 'DS giao mục tiêu CBNV trong đơn vị',
            link: 'kpi/kpi-bottom/list-employee',
            code: FunctionCode.KPI_EMP_OF_UNIT,
          },
        ],
      },
      {
        title: 'Đánh giá công ty',

        code: FunctionCode.KPI_ORG,
        children: [
          {
            title: 'Mục tiêu công ty',
            link: 'kpi/kpis-company/list',
            code: FunctionCode.KPIS_COMPANY,
          },
        ],
      },
      {
        title: 'DS đánh giá cá nhân',
        link: 'kpi/personal-evaluation/list',
        code: FunctionCode.KPI_PERSONAL_EVALUATION,
      },

      {
        title: 'DS giao mục tiêu cá nhân',
        link: 'kpi/kpi-confirm-target/list',
        code: FunctionCode.KPI_CONFIRM_TARGET,
      },
      {
        title: 'Giao - Đánh giá đơn vị',

        code: FunctionCode.DELIVERY_EVALUATE_UNIT,
        children: [
          {
            title: 'DS đánh giá đơn vị',
            link: 'kpi/unit-evaluation/list',
            code: FunctionCode.KPI_UNIT_EVALUATION,
          },
          {
            title: 'Giao mục tiêu đơn vị',
            link: 'kpi/kpi-unit/list',
            code: FunctionCode.KPI_UNIT,
          },
          {
            title: 'Xếp loại & danh hiệu đơn vị',
            link: 'kpi/rating-title-unit/list',
            code: FunctionCode.RATING_TITLE_UNIT,
          },
        ],
      },
      {
        title: 'Đánh giá CBNV',

        code: FunctionCode.KPI_MANAGE_EMP,
        children: [
          {
            title: 'DS đánh giá CBNV',
            link: 'kpi/manager-evaluate/list',
            code: FunctionCode.KPI_MANAGER_EVALUATE_EMP,
          },
          {
            title: 'DS đánh giá trực tiếp - gián tiếp',
            link: 'kpi/direct-indirect-evaluation/list',
            code: FunctionCode.KPI_DIRECT_INDIRECT_LIST,
          },
          {
            title: 'Phân bổ xếp loại & danh hiệu',
            link: 'kpi/manager-evaluate/evaluate-appellation',
            code: FunctionCode.KPI_EVALUATE_APPELLATION,
          },
          {
            title: 'Đơn vị duyệt PBXL & danh hiệu',
            link: 'kpi/personal-evaluation/unit-leader-list',
            code: FunctionCode.KPI_UNIT_LEADER,
          },
          {
            title: 'Hrbp phân bổ xếp loại & danh hiệu',
            link: 'kpi/manager-evaluate/hrbp-evaluate-appellation',
            code: FunctionCode.KPI_HRBP_EVALUATE_APPELLATION,
          },
          {
            title: 'DS đánh giá CEO-1',
            link: 'kpi/ceo-assessment/list',
            code: FunctionCode.KPI_MANAGE_CEO,
          },
        ],
      },
      {
        title: 'Cấu hình',

        code: FunctionCode.KPI_CONFIG,
        children: [
          {
            title: 'DS quản lý kỳ đánh giá',
            link: 'kpi/assessment-period-management/list',
            code: FunctionCode.KPI_ASSESSMENT_PERIOD_MANAGEMENT,
          },
          {
            title: 'DS bộ KPIs',
            link: 'kpi/assessment-period-management/list-of-kpi',
            code: FunctionCode.KPI_SET_LIST,
          },
          {
            title: 'Rule Đánh giá',
            link: 'kpi/setting/ratting-rule',
            code: FunctionCode.RATING_RULE,
          },
          {
            title: 'Rule xếp loại',
            link: 'kpi/rule-rating/list',
            code: FunctionCode.RATING_RULE_XL,
          },
        ],
      },
    ],
  },
  {
    title: 'Thôi việc',
    titleDisplay: 'Quản lý hợp đồng',
    icon: 'mci-qhe_laodong',
    children: [
      {
        title: 'Đơn thôi việc',
        link: '/employees/termination/resign-form',
        code: FunctionCode.RESIGNATION,
      },
      {
        title: 'Đơn vị báo nghỉ',
        link: '/employees/termination/resignation-letter',
        code: FunctionCode.RESIGN_REMEMBER,
      },
      {
        title: 'Thôi việc',
        link: '/employees/termination/approve-resignation-letter',
        code: FunctionCode.RESIGN_LIST,
      },
      {
        title: 'Xác nhận nghĩa vụ thôi việc',
        link: '/employees/termination/resign-obligation',
        code: FunctionCode.DUTY_LIST,
      },
      {
        title: 'Tiếp nhận bàn giao công việc',
        link: '/employees/termination/confirm-tranfer-work',
        code: FunctionCode.RECEIVE_HAND_OVER_LIST,
      },
      {
        title: 'Xác nhận bàn giao công việc',
        link: '/employees/termination/manager-confirm',
        code: FunctionCode.CONFIRM_HAND_OVER,
      },
      {
        title: 'Vận hành thôi việc',
        link: '/employees/termination/operate',
        code: FunctionCode.OPERATE_RESIGN_LIST,
      },
      {
        title: 'DS KQ chạy job QTLV',
        link: '/employees/termination/results-transfer-status-leave',
        code: FunctionCode.TRANSFER_RESULT,
      },
    ],
  },
  {
    title: 'Cài đặt',

    icon: 'mci-setting',
    code: FunctionCode.ADMINISTRATION,
    children: [
      {
        title: 'Danh mục',

        code: FunctionCode.CATEGORY_ADMIN,
      },
      {
        title: 'Master data',

        code: FunctionCode.MASTER_DATA,
      },
      {
        title: 'Chuyển đổi dữ liệu  ',

        children: [
          {
            title: 'Chuyển đổi dữ liệu nhân sự',
            link: 'setting/master-data/migrate-emp',
            code: FunctionCode.MIGRATE_EMP,
          },
          {
            title: 'Chuyển đổi dữ liệu bảo hiểm',
            link: 'setting/master-data/migrate-ins',
            code: FunctionCode.MIGRATE_INS,
          },
          {
            title: 'Chuyển đổi dữ liệu lương',
            link: 'setting/master-data/migrate-sl',
            code: FunctionCode.MIGRATE_SL,
          },
          {
            title: 'Chuyển đổi dữ liệu tổ chức',
            link: 'setting/master-data/migrate-org',
            code: FunctionCode.MIGRATE_ORG,
          },
          {
            title: 'Chuyển đổi dữ liệu thuế',
            link: 'setting/master-data/migrate-tax',
            code: FunctionCode.MIGRATE_TAX,
          },
        ],
      },
      {
        title: 'Tài khoản',
        link: '/setting/master-data/user',
        code: FunctionCode.ACCOUNT,
      },
      {
        title: 'Nhóm người dùng',
        link: '/setting/master-data/role',
        code: FunctionCode.USER_GROUP,
      },
      {
        title: 'Phân miền dữ liệu',
        link: '/setting/master-data/user-permission',
        code: FunctionCode.PERMISSION_DATA,
      },
      {
        title: 'Quản lý template email, SMS',

        code: FunctionCode.TEMPLATE_MANAGE,
      },
      {
        title: 'Cài đặt chức năng',

        code: FunctionCode.CONFIG,
        children: [
          {
            title: 'Danh sách gán quyền quản lý trực tiếp',
            link: '/employees/settings/list-assign-direct-management',
            code: FunctionCode.ASSIGNMENT_OF_MANAGEMENT_LIST,
          },
          {
            title: 'Gán quyền quản lý trực tiếp',
            link: '/employees/settings/assign-direct-management',
            code: FunctionCode.ASSIGNMENT_OF_MANAGEMENT,
          },
          {
            title: 'Cấu hình CEO phê duyệt ĐGHĐ, thôi việc',
            link: 'employees/settings/approve-ceo',
            code: FunctionCode.CONFIG_CEO,
          },
          {
            title: 'Cấu hình kiểm soát HR',
            link: 'employees/settings/control-hr',
            code: FunctionCode.CONFIG_HR,
          },
          {
            title: 'Cấu hình đầu mối dịch vụ nhân viên',
            link: '/employees/settings/configure-service-staff',
            code: FunctionCode.CONFIG_SERVICE,
          },
          {
            title: 'Khảo sát thôi việc',
            link: '/employees/termination/severance-survey',
            code: FunctionCode.SERVEY,
          },
          {
            title: 'Cài đặt mẫu hợp đồng',
            link: '/employees/contract-management/contract-template-settings',
            code: FunctionCode.CONTRACT_FORM,
          },
        ],
      },
      {
        title: 'Cài đặt dữ liệu',

        code: FunctionCode.CONFIG_DATA,
        children: [
          {
            title: 'Quốc tịch',
            link: '/setting/master-data/nationality',
            code: FunctionCode.NATIONALITY,
          },
          {
            title: 'Dân tộc',
            link: '/setting/master-data/ethnic',
            code: FunctionCode.ETHNIC,
          },
          {
            title: 'Tôn giáo',
            link: '/setting/master-data/religion',
            code: FunctionCode.RELIGION,
          },
          {
            title: 'Ngân hàng',
            link: '/setting/master-data/bank',
            code: FunctionCode.BANK,
          },
          {
            title: 'Chuyên ngành',
            link: '/setting/master-data/major',
            code: FunctionCode.SPECIALIZED,
          },
          {
            title: 'Chứng chỉ',
            link: '/setting/master-data/certificate',
            code: FunctionCode.CERTIFICATE,
          },
          {
            title: 'Ngành nghề',
            link: '/setting/master-data/industry',
            code: FunctionCode.CAREER,
          },
          {
            title: 'Khu vực',
            link: '/setting/master-data/area',
            code: FunctionCode.AREA,
          },
          {
            title: 'Nhóm',
            link: '/setting/master-data/group',
            code: FunctionCode.GROUP,
          },
          {
            title: 'Nhóm năng lực',
            link: '/setting/master-data/competence-group',
            code: FunctionCode.CAPACITY_GROUP,
          },
          {
            title: 'Từ điển năng lực',
            link: '/setting/master-data/competence-dictionary',
            code: FunctionCode.ABILITY,
          },
        ],
      },
      {
        title: 'Nhóm danh mục dùng chung',
        link: '/setting/master-data/common-category',
        code: FunctionCode.COMMON_CATEGORY,
      },
      {
        title: 'Quản lý tiến trình',
        link: '/setting/master-data/process-list',
        code: FunctionCode.PROGREES_LIST,
      },
      {
        title: 'Trạng thái đồng bộ',
        link: '/setting/master-data/synchronization-state',
        code: FunctionCode.SYNCHRONIZATION_STATE,
      },
    ],
  },
];

export const DAY_OF_WEEK = [
  {
    code: 1,
    vietnameseName: 'Thứ 2',
  },
  {
    code: 2,
    vietnameseName: 'Thứ 3',
  },
  {
    code: 3,
    vietnameseName: 'Thứ 4',
  },
  {
    code: 4,
    vietnameseName: 'Thứ 5',
  },
  {
    code: 5,
    vietnameseName: 'Thứ 6',
  },
  {
    code: 6,
    vietnameseName: 'Thứ 7',
  },
  {
    code: 7,
    vietnameseName: 'Chủ nhật',
  },
];

export enum ScreenTypeText {
  Create = 'create',
  Update = 'update',
  Detail = 'detail',
  APPROVAL = 'approval',
  APPROVE = 'approve',
  ADMIN = 'admin',
}

export const MENU_ADMIN = [
  {
    title: 'Trang chủ',
    link: '/dashboard',
    icon: 'mci-home',
    code: FunctionCode.DASHBOARD,
  },
  {
    title: 'Tổ chức',
    icon: 'mci-organization_new',
    children: [
      {
        title: 'Chuyển đổi mô hình tổ chức',
        icon: 'mci-home',
        code: FunctionCode.TRANSFER_ORG_MODEL,
        children: [
          {
            title: 'Mô hình tổ chức dự kiến',
            link: '/organization/structure-expected',
            icon: 'mci-home',
            code: FunctionCode.EXPECTED_ORG,
          },
          {
            title: 'Phương án luân chuyển nhân sự',
            link: '/organization/transfer-of-personnel/list',
            code: FunctionCode.DRAFT_TRANSFER_OF_PERSONNEL,
          },
          {
            title: 'Vị trí công việc dự kiến',
            link: '/organization/job-draft',
            code: FunctionCode.JOB_DRAFT,
          },
          {
            title: 'Sơ đồ tổ chức dự kiến',
            link: '/organization/org-tree-draft',
            code: FunctionCode.ORG_TREE_DRAFT,
          },
        ],
      },

      {
        title: 'Sơ đồ tổ chức',
        link: '/organization/tree',
        code: FunctionCode.ORG_CHART,
      },
      {
        title: 'Mô hình tổ chức',
        link: '/organization/model',
        code: FunctionCode.ORG_MODEL,
      },
      {
        title: 'Đơn vị',
        link: '/organization/unit',
        code: FunctionCode.MANAGEMENT_UNIT,
      },

      {
        title: 'Vị trí công việc',
        link: '/organization/job',
        code: FunctionCode.JOB,
      },
      {
        title: 'QL Mô tả công việc',
        link: '/organization/job-description',
        code: FunctionCode.JOB_DESCRIPTION,
      },

      {
        title: 'Thông tin doanh nghiệp',
        link: '/organization/profile',
        code: FunctionCode.BUSINESS_INFO,
      },
      {
        title: 'Danh mục',

        children: [
          {
            title: 'Vị trí',
            link: '/organization/location/list',
            code: FunctionCode.ORG_POSITION,
          },
          {
            title: 'Chức danh',
            link: '/organization/title-import/list',
            code: FunctionCode.ORG_TITLE,
          },
          {
            title: 'Trụ sở làm việc',
            link: '/organization/work-place',
            code: FunctionCode.WORK_LOCATION,
          },
        ],
      },
    ],
  },
  {
    title: 'Thông tin cá nhân',
    titleDisplay: 'Thông tin cá nhân',
    link: '/employees/employee-infor',
    icon: 'mci-employee',
    code: FunctionCode.EMP_INFO,
  },
  {
    title: 'Onboarding',
    icon: 'mci-onboarding',
    children: [
      {
        title: 'DS onboarding',
        link: '/onboarding/candidate-list',
        code: FunctionCode.OBN_LIST,
      },
      {
        title: 'DS duyệt thông tin UV',
        link: '/onboarding/information-browsing-list',
        code: FunctionCode.OBN_CANDIDATE_INFO_LIST,
      },
      {
        title: 'DS chuyển UV thành NV',
        link: '/onboarding/list-received',
        code: FunctionCode.TRANS_EMP_LIST,
      },
      {
        title: 'DS tạo tài khoản NV',
        link: '/onboarding/it-prepare-onb',
        code: FunctionCode.CREATE_ACC_LIST,
      },
      {
        title: 'DS chuẩn bị onboarding',
        link: '/onboarding/list-to-receive',
        code: FunctionCode.PREPARE_OBN_LIST,
      },
      {
        title: 'Báo cáo DS onboard',
        link: '/onboarding/utilities/full-data-onb',
        code: FunctionCode.REPORT_LIST,
      },
      {
        title: 'Lịch sử Onboarding',
        link: '/onboarding/onboarding-history',
        code: FunctionCode.ONB_HISTORY_LIST,
      },
      {
        title: 'Cấu hình',
        children: [
          {
            title: 'Hướng dẫn onboarding',
            link: '/onboarding/guide',
            code: FunctionCode.OBN_GUIDE,
          },
          {
            title: 'Cấu hình admin đơn vị duyệt thông tin ứng viên',
            link: '/employees/settings/admin-configuration',
            code: FunctionCode.CONFIG_ADMIN,
          },
        ],
      },
    ],
  },
  {
    title: 'Tổ chức nhân sự',
    titleDisplay: 'Tổ chức nhân sự',
    icon: 'mci-kehoachnhansu',
    children: [
      {
        title: 'Nhân sự',
        link: '/employees/employees-list',
        code: FunctionCode.EMP_LIST,
      },
      {
        title: 'Quá trình làm việc tại Mcredit',
        link: '/employees/list-working-process',
        code: FunctionCode.WORKING_PROCESS_MC,
      },
      {
        title: 'Kết quả đánh giá',
        link: '/employees/list-result-review',
        code: FunctionCode.RESULT_REVIEW,
      },
      {
        title: 'Kỷ luật',
        link: '/employees/discipline-list',
        code: FunctionCode.DISCIPLINE_LIST,
      },
      {
        title: 'Nơi làm việc',
        link: '/employees/workplace',
        code: FunctionCode.EMP_REPORT_FULL_DATA,
      },
      {
        title: 'Báo cáo full data',
        link: '/employees/report/employee-full-data',
        code: FunctionCode.WORKPLACE,
      },
      {
        title: 'Tiện ích',
        code: FunctionCode.EXTENSION,
        children: [
          {
            title: 'Chuyển mã nhân viên',
            link: '/employees/transform-employee-code',
            code: FunctionCode.TRANSFORM_EMP_CODE,
          },
          {
            title: 'Cập nhật mã NV cơ sở',
            link: '/onboarding/update-emp-code',
            code: FunctionCode.ONB_UPDATE_EMP_CODE,
          },
          {
            title: 'Cấu hình đầu mối nhân sự',
            link: '/organization/configuring-HR-focal-point/list',
            code: FunctionCode.CONFIG_HUMAN_RESOURCES,
          },
          {
            title: 'Sổ Văn Bản',
            link: '/employees/termination/text-book',
            code: FunctionCode.DOCUMENT_BOOK,
          },
        ],
      },
      {
        title: 'Báo cáo',
        code: FunctionCode.EXTENSION,
        children: [
          {
            title: 'Báo cáo full data',
            link: '/employees/full-data-report',
            code: FunctionCode.FULL_DATA_EMPLOYEE_REPORT,
          },
        ],
      },
      {
        title: 'DS quản lý đơn vị',
        link: '/employees/list-management-units',
        code: FunctionCode.UNIT_MANAGE_LIST,
      },
    ],
  },
  {
    title: 'Duyệt thông tin',
    titleDisplay: 'Duyệt thông tin nhân sự',
    icon: 'mci-duyetthongtin_nhansu',
    children: [
      {
        title: 'Duyệt thông tin nhân sự',
        link: '/employees/approval-request-list',
        code: FunctionCode.APPROVAL_REQUEST_LIST,
      },

      {
        title: 'Đơn vị duyệt đăng ký mượn hồ sơ',
        link: '/employees/online-filing-cabinet/browser-unit',
        code: FunctionCode.APPROVAL_REGIS_UNIT,
      },
      {
        title: 'HR duyệt đăng ký mượn hồ sơ',
        link: '/employees/online-filing-cabinet/browser-hr',
        code: FunctionCode.APPROVAL_REGIS_HR,
      },
      {
        title: 'Duyệt đơn nghỉ',
        link: '/timesheet/approve-leave-of-absence',
        code: FunctionCode.APPROVE_LEAVE_OF_ABSENCE,
      },
      {
        title: 'Duyệt bảng công',
        link: '/timesheet/approve-timesheets',
        code: FunctionCode.APPROVE_TIMESHEETS,
      },
      {
        title: 'Duyệt bảng công backdate',
        link: '/timesheet/approve-timesheets-backdate',
        code: FunctionCode.APPROVE_TIMESHEETS_BACKDATE,
      },
    ],
  },
  {
    title: 'Tủ hồ sơ',
    icon: 'mci-tuhoso_online',
    titleDisplay: 'Tủ hồ sơ online',
    children: [
      {
        title: 'Thông tin hồ sơ',
        link: 'employees/online-filing-cabinet/profile-information',
        code: FunctionCode.PROFILE_INFORMATION,
      },
      {
        title: 'Báo cáo thiếu đủ hồ sơ',
        link: '/employees/online-filing-cabinet/personnel-record-summary',
        code: FunctionCode.LESS_REPORT_FILE,
      },
      {
        title: 'Báo cáo thiếu đủ hồ sơ chi tiết',
        link: '/employees/online-filing-cabinet/personnel-record-summary-detail',
        code: FunctionCode.LESS_REPORT_DETAIL_FILE,
      },
      {
        title: 'Theo dõi mượn trả hồ sơ',
        link: '/employees/online-filing-cabinet/borrow-and-return-documents',
        code: FunctionCode.FOLLOW_FILE,
      },
      {
        title: 'Đăng ký mượn hồ sơ',
        link: '/employees/online-filing-cabinet/borrow-employee-files',
        code: FunctionCode.REGIS_BORROW,
      },
      {
        title: 'Cấu hình',

        code: FunctionCode.CONFIG_EMP,
        children: [
          {
            title: 'Danh mục hồ sơ',
            link: 'employees/online-filing-cabinet/profile-list',
            code: FunctionCode.DOCUMENT_REGULATION,
          },
          {
            title: 'Quy định hồ sơ',
            link: 'employees/online-filing-cabinet/profile-regulations',
            code: FunctionCode.REGULATION,
          },
        ],
      },
    ],
  },
  {
    title: 'Thông tin lao động',
    titleDisplay: 'Quan hệ lao động',
    icon: 'mci-qhe_laodong',
    children: [
      {
        title: 'Quản lý hợp đồng',
        children: [
          {
            title: 'DS hợp đồng',
            link: '/employees/contract-management/list-contract',
            code: FunctionCode.CONTRACT_OPERATION,
          },
          {
            title: 'Đến hạn hợp đồng',
            link: '/employees/contract-management/contract-expiration',
            code: FunctionCode.CONTRACT_EXPIRATION,
          },
          {
            title: 'DS Đánh giá tái ký',
          },
          {
            title: 'Đề xuất chuyển loại',
            link: '/employees/contract-management/change-type-contract',
            code: FunctionCode.OFFER_TYPE_TRANS,
          },
          {
            title: 'Danh sách đánh giá hợp đồng',
            link: '/employees/contract-management/contract-self-assessment',
            code: FunctionCode.CONTRACT_SELF_ASSESSMENT,
          },
          {
            title: 'Tái ký hợp đồng',
            link: '/employees/contract-management/contract-assessment-list',
            code: FunctionCode.RENEWS_CONTRACT,
          },
          {
            title: 'Uỷ quyền hợp đồng',
            link: '/employees/contract-management/contract-authorization',
            code: FunctionCode.CONTRACT_AUTHORIZATION,
          },
          {
            title: 'Tự đánh giá hợp đồng',
            link: '/employees/contract-management/contract-self-assessment',
            code: FunctionCode.CONTRACT_SELF_ASSESSMENT_ACTIVE,
          },
          {
            title: 'Loại hợp đồng',
            link: '/employees/category-common/contract',
            code: FunctionCode.CONTRACT_CATEGORY,
          },
        ],
      },
      {
        title: 'Thôi việc',
        children: [
          {
            title: 'Đơn thôi việc',
            link: '/employees/termination/resign-form',
            code: FunctionCode.RESIGNATION,
          },
          {
            title: 'Đơn vị báo nghỉ',
            link: '/employees/termination/resignation-letter',
            code: FunctionCode.RESIGN_REMEMBER,
          },
          {
            title: 'Thôi việc',
            link: '/employees/termination/approve-resignation-letter',
            code: FunctionCode.RESIGN_LIST,
          },
          {
            title: 'Xác nhận nghĩa vụ thôi việc',
            link: '/employees/termination/resign-obligation',
            code: FunctionCode.DUTY_LIST,
          },
          {
            title: 'Tiếp nhận bàn giao công việc',
            link: '/employees/termination/confirm-tranfer-work',
            code: FunctionCode.RECEIVE_HAND_OVER_LIST,
          },
          {
            title: 'Xác nhận bàn giao công việc',
            link: '/employees/termination/manager-confirm',
            code: FunctionCode.CONFIRM_HAND_OVER,
          },
          {
            title: 'Vận hành thôi việc',
            link: '/employees/termination/operate',
            code: FunctionCode.OPERATE_RESIGN_LIST,
          },
          {
            title: 'DS KQ chạy job QTLV',
            link: '/employees/termination/results-transfer-status-leave',
            code: FunctionCode.TRANSFER_RESULT,
          },
          {
            title: 'Sổ Văn Bản',
            link: '/employees/termination/text-book',
            code: FunctionCode.DOCUMENT_BOOK,
          },
        ],
      },
      {
        title: 'Cấu hình',
        code: FunctionCode.LABOR_RELATIONS_SETTING,
        children: [
          {
            title: 'Quản lý DOA hoạt động nhân sự',
            link: '/employees/doa/list',
            code: FunctionCode.DOA_HR,
          },
          {
            title: 'Chỉ tiêu đánh giá hợp đồng',
            link: '/employees/settings/contract-criteria',
            code: FunctionCode.ASSESSMENT_CRITERIA,
          },
          {
            title: 'Kết luận đánh giá hợp đồng',
            link: '/employees/settings/contract-conclusion',
            code: FunctionCode.CONTRACT_CONCLUSION_ASSESSMENT,
          },
        ],
      },
    ],
  },
  {
    title: 'Chấm công',

    icon: 'mci-timesheet',
    code: FunctionCode.TIMESHEET,
    children: [
      {
        title: 'Lịch phân công trực đơn vị',
        link: '/timesheet/assign-off-schedule',
        code: FunctionCode.ASSIGN_OFF_SCHEDULE,
      },
      {
        title: 'Đơn nghỉ cá nhân',
        link: '/timesheet/register-off',
        code: FunctionCode.REGISTEER_OFF,
      },
      {
        title: 'Đơn nghỉ',
        link: '/timesheet/list-off',
        code: FunctionCode.LIST_OFF,
      },
      {
        title: 'Tổng hợp bảng công',
        link: '/timesheet/timesheets',
        code: FunctionCode.TIMESHEETS,
      },
      {
        title: 'Tổng hợp bảng công backdate',
        link: '/timesheet/timesheets-backdate',
        code: FunctionCode.TIMESHEETS_BACKDATE,
      },
      {
        title: 'Cấu hình',

        children: [
          {
            title: 'Lịch làm việc',
            link: '/timesheet/assign-work-schedule',
            code: FunctionCode.ASSIGN_WORK_SCHEDULE,
          },
          {
            title: 'Kỳ chấm công',
            link: 'timesheet/timekeeping-period',
            code: FunctionCode.TIMEKEEPING_PERIOD,
          },
          {
            title: 'Ngày nghỉ lễ ',
            link: '/timesheet/holiday-calender',
            code: FunctionCode.HOLIDAY_CALENDER,
          },
          {
            title: 'Nhân viên chưa thiết lập lịch làm việc',
            link: '/timesheet/establish-schedule-employee',
            code: FunctionCode.ESTABLISH_SCHEDULE_EMPLOYEE,
          },
        ],
      },
    ],
  },
  {
    title: 'Lương thưởng',

    code: FunctionCode.SALARY_BONUS,
    icon: 'mci-luongDN',
    children: [
      {
        title: 'Cấu hình',

        code: FunctionCode.CONFIG_SALARY,
        children: [
          {
            title: 'Mức lương',
            link: '/salary/salary-bonus-config/list',
            code: FunctionCode.SALARY_CONFIGURATION,
          },
          {
            title: 'Dải lương',
            link: '/salary/salary-range/list',
            code: FunctionCode.SALARY_RANGE,
          },
          {
            title: 'Quy chế lương',
            link: '/salary/regulation-salary/list',
            code: FunctionCode.SALARY_REGULATION,
          },
          {
            title: 'Cài đặt phụ cấp',
            link: '/salary/settings/salary-settings/allowance-settings',
          },
          {
            title: 'Tạo kỳ lương',
            link: '/salary/salary-period/list',
            code: FunctionCode.SALARY_PERIOD_CATEGORY,
          },
          {
            title: 'Công thức lương',
            link: '/salary/settings/salary-settings/salary-formula',
          },
          {
            title: 'Tỷ lệ THS theo KQ kinh doanh công ty',
            link: '/salary/company-interest/list',
            code: FunctionCode.FN_COMPANY_INTEREST_RATE,
          },
          {
            title: 'Chính sách dải lương',
            link: '/salary/salary-level-config/list',
            code: FunctionCode.SALARY_LEVEL_CONFIG,
          },
          {
            title: 'Chính sách phụ cấp',
            link: '/salary/allowance-policy/list',
            code: FunctionCode.SALARY_ALLOWANCE_CONFIG,
          },
          {
            title: 'Danh sách cấp bậc nội bộ',
            link: '/salary/internal-level/list',
            code: FunctionCode.LIST_HIERARCHY_INTERNAL,
          },
        ],
      },
      {
        title: 'Đầu vào lương',

        children: [
          {
            title: 'Diễn biến lương cơ bản, mức căn cứ tính thưởng',
            link: '/salary/bonus-base/personal/list',
            code: FunctionCode.BONUS_BASE_DEVELOPMENT,
          },
          {
            title: 'Diễn biến thù lao',
            link: '/salary/remuneration/single-list',
            code: FunctionCode.DEVELOPMENT_REMUNERATION_EMP,
          },
          {
            title: 'Diễn biến phụ cấp',
            link: '/salary/allowances/single-list',
            code: FunctionCode.DEVELOPMENT_OF_ACCESSORY,
          },
          {
            title: 'DS diễn biến LCB, MCCTT',
            link: '/salary/bonus-base/multiple/list',
            code: FunctionCode.BONUS_BASE_LIST,
          },
          {
            title: 'DS diễn biến thù lao',
            link: '/salary/remuneration/list',
            code: FunctionCode.REMUNERATION,
          },
          {
            title: 'DS diễn biến phụ cấp',
            link: '/salary/allowances/list',
            code: FunctionCode.ALLOWANCE_LIST,
          },
          {
            title: 'Thực thi chính sách phụ cấp',
            link: '/salary/implement-allowance-policy/list',
            code: FunctionCode.POLICY_IMPLEMENTATION,
          },
          {
            title: 'Điều chỉnh thu nhập',
            link: '/salary/allowances/adjustment',
            code: FunctionCode.INCOME_ADJUSTMENT,
          },
          {
            title: 'Bù trừ lương',
            link: '/salary/compensate-deduct/list',
            code: FunctionCode.SALARY_OFFSET,
          },
          {
            title: 'Trợ cấp thôi việc',
            link: '/salary/severance-allowance/list',
            code: FunctionCode.SEVERANCE_ALLOWANCE,
          },
        ],
      },
      {
        title: 'Lương tháng',

        children: [
          {
            title: 'Đợt lương',
            link: '/salary/batch-salary/list',
            code: FunctionCode.SALARY_PERIOD,
          },
          {
            title: 'Xem bảng lương',
            link: '/salary/payroll/list',
            code: FunctionCode.PAYROLL,
          },
          {
            title: 'Tính lương tháng',
            link: '/salary/calculation-salary-period/list',
            code: FunctionCode.CALCULATE_MONTH_SALARY,
          },
          {
            title: 'Phiếu lương',
            link: '/salary/payslip/list',
            code: FunctionCode.PAYSLIP,
          },
        ],
      },
      {
        title: 'Lương treo',

        code: FunctionCode.SALARY_CALCULATION,
        children: [
          {
            title: 'Danh sách lương treo',
            link: '/salary/hanging-salary/list',
            code: FunctionCode.SALARY_HOLDING,
          },
          {
            title: 'Quyết toán lương treo',
            link: 'salary/settlement-of-suspended-salary/list',
            code: FunctionCode.HOLDING_FINALIZATION,
          },
          {
            title: 'Thu hồi lương treo',
            link: '/salary/recall-confirmation/list',
            code: FunctionCode.SALARY_CALCULATION_RECOVERY,
          },
        ],
      },
      {
        title: 'Phiếu lương',
        link: '/salary/payslip/list-data',
        code: FunctionCode.PAYSLIP_YOURSELF,
      },
      {
        title: 'Diễn biến LCB, MCCTT',
        link: 'salary/evolution/personal/list',
        code: FunctionCode.YOURSELF_BONUS_BASE_DEVELOPMENT,
      },
      {
        title: 'Diễn biến thù lao',
        link: 'salary/remuneration-evolution/single-list',
        code: FunctionCode.YOURSELF_DEVELOPMENT_REMUNERATION_EMP,
      },
      {
        title: 'Diễn biến phụ cấp',
        link: 'salary/allowances-evolution/single-list',
        code: FunctionCode.YOURSELF_DEVELOPMENT_OF_ACCESSORY,
      },
    ],
  },
  {
    title: 'Kế toán lương',

    icon: 'mci-thue',
    children: [
      {
        title: 'Thuế thu nhập cá nhân',

        children: [
          {
            title: 'Thuế thu nhập cá nhân tháng',
            link: '/accountant/monthly-personal-income/list',
            code: FunctionCode.MONTHLY_PERSONAL_INCOME_TAX,
          },
          {
            title: 'Danh sách cam kết thuế',
            link: '/tax/tax-commitment/list',
            code: FunctionCode.TAX_COMMITMENT,
          },
          {
            title: 'Danh sách giảm trừ gia cảnh',
            link: '/accountant/family-deduction/list',
            code: FunctionCode.FAMILY_DEDUCTION,
          },
        ],
      },
    ],
  },
  {
    title: 'Bảo hiểm',

    icon: 'mci-timesheet',
    children: [
      {
        title: 'Bảo hiểm bắt buộc',

        children: [
          {
            title: 'Thông tin đóng bảo hiểm',
            link: '/insurance/insurance-payment-information/list',
            code: FunctionCode.INSURANCE_INFO,
          },
          {
            title: 'Diễn biến lương cơ bản',
            link: '/insurance/salary-evolution',
            code: FunctionCode.SALARY_EVOLUTION,
          },
          {
            title: 'Báo tăng bảo hiểm',
            link: '/insurance/insurance-increase-report/list',
            code: FunctionCode.INSURANCE_UP,
          },
          {
            title: 'Báo giảm bảo hiểm',
            link: '/insurance/reduce-insurance-report/list',
            code: FunctionCode.INSURANCE_DOWN,
          },
          {
            title: 'Người lao động kê khai',
            link: '/insurance/laborers-declaration/list',
            code: FunctionCode.INSURANCE_DECLARE_LIST,
          },
          {
            title: 'Điều chỉnh bảo hiểm',
            link: '/insurance/insurance-adjustment/list',
            code: FunctionCode.INSURANCE_ADJUSTMENT,
          },
          {
            title: 'DS đóng bảo hiểm',
            link: '/insurance/take-insuarance/list',
            code: FunctionCode.TAKE_INSURANCE,
          },
          {
            title: 'Trích nộp',
            link: '/insurance/deduction/list',
            code: FunctionCode.DEDUCTION,
          },
        ],
      },
      {
        title: 'Kê khai tham gia bảo hiểm',
        link: 'insurance/insurance-information/labor-insurance-regist',
        code: FunctionCode.INSURANCE_DECLARATION,
      },

      {
        title: 'Thẻ BHYT',
      },
      {
        title: 'Sổ BHXH',
      },
      {
        title: 'Quá trình đóng BH',
      },
      {
        title: 'Báo cáo',
      },
    ],
  },
  {
    title: 'Quản trị thành tích',

    icon: 'mci-kpi',
    code: FunctionCode.KPIS,
    children: [
      {
        title: 'Giao mục tiêu CBNV',

        code: FunctionCode.KPI_PERSONAL,
        children: [
          {
            title: 'DS giao mục tiêu trực tiếp',
            link: 'kpi/kpi-bottom/list',
            code: FunctionCode.KPI_BOTTOM,
          },
          {
            title: 'DS giao mục tiêu CBNV trong đơn vị',
            link: 'kpi/kpi-bottom/list-employee',
            code: FunctionCode.KPI_EMP_OF_UNIT,
          },
        ],
      },
      {
        title: 'Đánh giá công ty',

        code: FunctionCode.KPI_ORG,
        children: [
          {
            title: 'Mục tiêu công ty',
            link: 'kpi/kpis-company/list',
            code: FunctionCode.KPIS_COMPANY,
          },
        ],
      },
      {
        title: 'DS đánh giá cá nhân',
        link: 'kpi/personal-evaluation/list',
        code: FunctionCode.KPI_PERSONAL_EVALUATION,
      },
      {
        title: 'Lãnh đạo đơn vị duyệt PBXL và danh hiệu cá nhân',
        link: 'kpi/personal-evaluation/unit-leader-list',
        code: FunctionCode.KPI_UNIT_LEADER,
      },
      {
        title: 'DS giao mục tiêu cá nhân',
        link: 'kpi/kpi-confirm-target/list',
        code: FunctionCode.KPI_CONFIRM_TARGET,
      },
      {
        title: 'Giao - Đánh giá đơn vị',

        code: FunctionCode.DELIVERY_EVALUATE_UNIT,
        children: [
          {
            title: 'DS đánh giá đơn vị',
            link: 'kpi/unit-evaluation/list',
            code: FunctionCode.KPI_UNIT_EVALUATION,
          },
          {
            title: 'Giao mục tiêu đơn vị',
            link: 'kpi/kpi-unit/list',
            code: FunctionCode.KPI_UNIT,
          },
          {
            title: 'Xếp loại & danh hiệu đơn vị',
            link: 'kpi/rating-title-unit/list',
            code: FunctionCode.RATING_TITLE_UNIT,
          },
        ],
      },
      {
        title: 'Đánh giá CBNV',

        code: FunctionCode.KPI_MANAGE_EMP,
        children: [
          {
            title: 'DS đánh giá CBNV',
            link: 'kpi/manager-evaluate/list',
            code: FunctionCode.KPI_MANAGER_EVALUATE_EMP,
          },
          {
            title: 'DS đánh giá trực tiếp - gián tiếp',
            link: 'kpi/direct-indirect-evaluation/list',
            code: FunctionCode.KPI_DIRECT_INDIRECT_LIST,
          },
          {
            title: 'Phân bổ xếp loại & danh hiệu',
            link: 'kpi/manager-evaluate/evaluate-appellation',
            code: FunctionCode.KPI_EVALUATE_APPELLATION,
          },
          {
            title: 'Hrbp phân bổ xếp loại & danh hiệu',
            link: 'kpi/manager-evaluate/hrbp-evaluate-appellation',
            code: FunctionCode.KPI_HRBP_EVALUATE_APPELLATION,
          },
          {
            title: 'DS đánh giá CEO-1',
            link: 'kpi/ceo-assessment/list',
            code: FunctionCode.KPI_MANAGE_CEO,
          },
        ],
      },
      {
        title: 'Cấu hình',

        code: FunctionCode.KPI_CONFIG,
        children: [
          {
            title: 'DS quản lý kỳ đánh giá',
            link: 'kpi/assessment-period-management/list',
            code: FunctionCode.KPI_ASSESSMENT_PERIOD_MANAGEMENT,
          },
          {
            title: 'DS bộ KPIs',
            link: 'kpi/assessment-period-management/list-of-kpi',
            code: FunctionCode.KPI_SET_LIST,
          },
          {
            title: 'Rule Đánh giá',
            link: 'kpi/setting/ratting-rule',
            code: FunctionCode.RATING_RULE,
          },
          {
            title: 'Rule xếp loại',
            link: 'kpi/rule-rating/list',
            code: FunctionCode.RATING_RULE_XL,
          },
        ],
      },
    ],
  },
  {
    title: 'Cài đặt',

    icon: 'mci-setting',
    code: FunctionCode.ADMINISTRATION,
    children: [
      {
        title: 'Danh mục',

        code: FunctionCode.CATEGORY_ADMIN,
      },
      {
        title: 'Master data',

        code: FunctionCode.MASTER_DATA,
      },
      {
        title: 'Chuyển đổi dữ liệu (Migrate data)',

        children: [
          {
            title: 'Chuyển đổi dữ liệu nhân sự',
            link: 'setting/master-data/migrate-emp',
            code: FunctionCode.MIGRATE_EMP,
          },
          {
            title: 'Chuyển đổi dữ liệu bảo hiểm',
            link: 'setting/master-data/migrate-ins',
            code: FunctionCode.MIGRATE_INS,
          },
          {
            title: 'Chuyển đổi dữ liệu lương',
            link: 'setting/master-data/migrate-sl',
            code: FunctionCode.MIGRATE_SL,
          },
          {
            title: 'Chuyển đổi dữ liệu tổ chức',
            link: 'setting/master-data/migrate-org',
            code: FunctionCode.MIGRATE_ORG,
          },
          {
            title: 'Chuyển đổi dữ liệu thuế',
            link: 'setting/master-data/migrate-tax',
            code: FunctionCode.MIGRATE_TAX,
          },
        ],
      },
      {
        title: 'Tài khoản',
        link: '/setting/master-data/user',
        code: FunctionCode.ACCOUNT,
      },
      {
        title: 'Nhóm người dùng',
        link: '/setting/master-data/role',
        code: FunctionCode.USER_GROUP,
      },
      {
        title: 'Phân quyền',
        link: '/setting/master-data/decentralization',
        code: FunctionCode.USER_PERMISSION,
      },
      {
        title: 'Migrate-data',
        link: '/setting/master-data/migrate-actionData',
        code: FunctionCode.USER_PERMISSION,
      },
      {
        title: 'Phân miền dữ liệu',
        link: '/setting/master-data/user-permission',
        code: FunctionCode.PERMISSION_DATA,
      },
      {
        title: 'Quản lý template email, SMS',

        code: FunctionCode.TEMPLATE_MANAGE,
      },
      {
        title: 'Cài đặt chức năng',

        code: FunctionCode.CONFIG,
        children: [
          {
            title: 'Danh sách gán quyền quản lý trực tiếp',
            link: '/employees/settings/list-assign-direct-management',
            code: FunctionCode.ASSIGNMENT_OF_MANAGEMENT_LIST,
          },
          {
            title: 'Gán quyền quản lý trực tiếp',
            link: '/employees/settings/assign-direct-management',
            code: FunctionCode.ASSIGNMENT_OF_MANAGEMENT,
          },
          {
            title: 'Cấu hình CEO phê duyệt ĐGHĐ, thôi việc',
            link: 'employees/settings/approve-ceo',
            code: FunctionCode.CONFIG_CEO,
          },
          {
            title: 'Cấu hình kiểm soát HR',
            link: 'employees/settings/control-hr',
            code: FunctionCode.CONFIG_HR,
          },
          {
            title: 'Cấu hình đầu mối dịch vụ nhân viên',
            link: '/employees/settings/configure-service-staff',
            code: FunctionCode.CONFIG_SERVICE,
          },
          {
            title: 'Khảo sát thôi việc',
            link: '/employees/termination/severance-survey',
            code: FunctionCode.SERVEY,
          },
          {
            title: 'Cài đặt mẫu hợp đồng',
            link: '/employees/contract-management/contract-template-settings',
            code: FunctionCode.CONTRACT_FORM,
          },
        ],
      },
      {
        title: 'Cài đặt dữ liệu',

        code: FunctionCode.CONFIG_DATA,
        children: [
          {
            title: 'Quốc tịch',
            link: '/setting/master-data/nationality',
            code: FunctionCode.NATIONALITY,
          },
          {
            title: 'Dân tộc',
            link: '/setting/master-data/ethnic',
            code: FunctionCode.ETHNIC,
          },
          {
            title: 'Tôn giáo',
            link: '/setting/master-data/religion',
            code: FunctionCode.RELIGION,
          },
          {
            title: 'Ngân hàng',
            link: '/setting/master-data/bank',
            code: FunctionCode.BANK,
          },
          {
            title: 'Chuyên ngành',
            link: '/setting/master-data/major',
            code: FunctionCode.SPECIALIZED,
          },
          {
            title: 'Chứng chỉ',
            link: '/setting/master-data/certificate',
            code: FunctionCode.CERTIFICATE,
          },
          {
            title: 'Ngành nghề',
            link: '/setting/master-data/industry',
            code: FunctionCode.CAREER,
          },
          {
            title: 'Khu vực',
            link: '/setting/master-data/area',
            code: FunctionCode.AREA,
          },
          {
            title: 'Nhóm',
            link: '/setting/master-data/group',
            code: FunctionCode.GROUP,
          },
          {
            title: 'Nhóm năng lực',
            link: '/setting/master-data/competence-group',
            code: FunctionCode.CAPACITY_GROUP,
          },
          {
            title: 'Từ điển năng lực',
            link: '/setting/master-data/competence-dictionary',
            code: FunctionCode.ABILITY,
          },
        ],
      },
      {
        title: 'Nhóm danh mục dùng chung',
        link: '/setting/master-data/common-category',
        code: FunctionCode.COMMON_CATEGORY,
      },
      {
        title: 'Quản lý tiến trình',
        link: '/setting/master-data/process-list',
        code: FunctionCode.PROGREES_LIST,
      },
      {
        title: 'Trạng thái đồng bộ',
        link: '/setting/master-data/synchronization-state',
        code: FunctionCode.SYNCHRONIZATION_STATE,
      },
    ],
  },
];

export enum CONTRACT_STATUS {
  UPDATING = 'UPDATING', //"Đang cập nhật"
  NOT_SEND = 'NOT_SEND', //"Chưa gửi"
  WAITING_APPROVE = 'WAITING_APPROVE', //"Chờ cấp trên phê duyệt"
  APPROVING = 'APPROVING', //"Chờ duyệt"
  ALL_COMPLETE = 'ALL_COMPLETE', //"Hoàn tất phê duyệt"
  RETURNED = 'RETURNED', //"Bị trả lại"
  RETURN = 'RETURN', //"Trả lại"
}

export enum ADMIN_UNIT_CONTRACT_STATUS {
  ADMIN_UPDATING = 'ADMIN_UPDATING', // "Đang cập nhật"
  CBQL_APPROVING = 'CBQL_APPROVING', // "Chờ duyệt CBQL"
  HR_APPROVING = 'HR_APPROVING', //"Chờ duyệt HR"
  LAST_APPROVING = 'LAST_APPROVING', // "Chờ duyệt cấp phê duyệt"
  ALL_COMPLETE = 'ALL_COMPLETE', //"Hoàn tất phê duyệt"
  ADMIN_RETURNED = 'ADMIN_RETURNED', // bị trả lại
}

export enum GRADE_CONFIG {
  HEADER = 'HEADER',
  CONTROLLER = 'CONTROLLER',
  LEADER = 'LEADER',
  DCEO = 'DCEO',
}

export enum CONTRACT_GROUP_TYPE {
  LO_GOM = 'G', // Lô gom hoặc lô gộp
  LO_GOC = 'I', // lô gốc
}

export enum RootCode {
  MC_HRIS = 'MC_HRIS',
}

export enum WorkTypeEmp {
  EMP_First = '0',
  EMP_SECOND = '1',
}

export enum CONTRACT_ASSESSMENT_STATUS {
  SELF_UPDATING = 'SELF_UPDATING',
  SELF_ASSESSMENT = 'SELF_ASSESSMENT',
  SELF_WAITING = 'SELF_WAITING',
  DIRECT_UPDATING = 'DIRECT_UPDATING',
  DIRECT_ASSESSMENT = 'DIRECT_ASSESSMENT',
  NOT_SEND = 'NOT_SEND',
  WAITING_APPROVE = 'WAITING_APPROVE',
  APPROVING = 'APPROVING',
  ALL_COMPLETE = 'ALL_COMPLETE',
  RETURNED = 'RETURNED',
  RETURN = 'RETURN',
  EMPLOYEE_ASSESSMENT = 'EMPLOYEE_ASSESSMENT',
  CBQL_APPROVING = 'CBQL_APPROVING',
  HR_APPROVING = 'HR_APPROVING',
  LAST_APPROVING = 'LAST_APPROVING',
  ADMIN_UPDATING = 'ADMIN_UPDATING',
  ADMIN_RETURNED = 'ADMIN_RETURNED',
}

export enum KPIS_AKES_MASTER_STATUS {
  KPIS_PENDING = '0', // chưa giao
  KPIS_TAKING = '1', // đang giao
  KPIS_SELF_REVIEW = '1A', // tự dánh giá
  KPIS_PENDING_ACCEPTED = '2', // chờ xác nhận
  KPIS_PENDING_APPROVED = '3', // chờ duyệt
  KPIS_REJECT = '4', // từ chối
  KPIS_RETURNED = '5', // trả lại
  KPIS_COMPLETED = '6', // đã hoàn thành
}

export enum KPIS_PRE_MASTER_STATUS {
  KPIS_PENDING = '0', // chưa đánh giá
  KPIS_EMPLOYEE_SELF_REVIEWING = '0A', // CBNV đang đánh giá
  KPIS_RETURNED = '0B', // QLTT trả lại
  KPIS_REVIEWING = '1', // QLTT đang đánh giá
  KPIS_PENDING_ACCEPTED = '2', // chờ CBNV xác nhận
  KPIS_PENDING_APPROVED = '3', // chờ QLGT duyệt (không có thì là hoàn thành)
  KPIS_EMP_REJECT = '4', // CBNV từ chối
  KPIS_MANAGER_RETURNED = '5', // QLTT trả lại
  KPIS_COMPLETED = '6', // đã haofn thành
}

export enum KPIS_PERIOD_STATUS {
  STATUS_OPEN_TARGET = 1, // mở mục tiêu
  STATUS_OPEN_REVIEW = 2, // mở đánh giá
  STATUS_OPEN_DIVIDE = 3, // mwor phân bỏ
  STATUS_LOCK = 4, // đã khóa
}
